import { IconButton, List, ListItem, Popover, Tooltip } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import enIcon from '../../../../../media/icons/en.svg';
import deIcon from '../../../../../media/icons/de.svg';

function HeaderLanguageSwitch() {
  const ref = useRef<any>(null);
  const { t, i18n } = useTranslation();
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChangeLang = (): void => {
    handleClose();
    var newLang = i18n.language == 'en'? 'de' : 'en';
    i18n.changeLanguage(newLang);
    localStorage.setItem('lng', newLang);
  }

  return (
    <>
      <Tooltip arrow title={t('language')}>
        <IconButton color="info" ref={ref} onClick={handleOpen}>
            <img src={i18n.language == 'en'? enIcon : deIcon } alt="" width={26} />
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <List sx={{ p: 0 }}>
          <ListItem
            sx={{ p: 2, minWidth: 136, display: { xs: 'block', sm: 'flex' } }}
            button
            onClick={handleChangeLang}
          >
            <img src={i18n.language == 'en'? deIcon : enIcon} alt="" width={26} style={{marginRight: 10}} /> {i18n.language == 'en'? 'Deutsch' : 'English'}
          </ListItem>
        </List>
      </Popover>
    </>
  );
}

export default HeaderLanguageSwitch;
