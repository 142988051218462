import { useTranslation } from "react-i18next";
import { GridColDef, GridRowsProp, GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport } from '@mui/x-data-grid';
import ApiService from "../../../services/ApiService";
import { useEffect, useState } from "react";
import { Box, Card, Container, Grid, Typography, LinearProgress, capitalize, Divider } from "@mui/material";
import { StyledDataGrid } from "../../../components/Table/TableStyleGrid";
import { showWithToolTip } from "../../../components/Table/CellToolTip";

function UserLists(props: any) {
  const { t } = useTranslation();
  const { orgID } = props;

  const [tableLoading, setTableLoading] = useState(false);

  const initialRows: GridRowsProp = [];
  const [rows, setRows] = useState(initialRows);

  const columns: GridColDef[] = [
    { field: 'name', headerName: t('name'), flex: 1 },
    { field: 'email', headerName: t('email'), flex: 2, renderCell: showWithToolTip },
    { field: 'company_name', headerName: t('company_name'), flex: 2, renderCell: showWithToolTip, valueGetter: (params) => {
      if(params.row.role == 'Account Manager') {
        return props.orgData.company_name;
      } else {
        return params.row.company_name
      }
    }},
    {
      field: 'role', headerName: t('role'), width: 150, valueFormatter(params) {
        return capitalize(params.value);
      },
    },
  ];

  const getOrgUsers = async () => {
    setTableLoading(true);
    const response = await ApiService.getUsers({ organization_id: orgID });

    setTableLoading(false);
    if (response.status == 'success') {
      setRows(response.data);
    } else {

    }
  }

  useEffect(() => {
    (async () => {
      getOrgUsers();
    })();
  }, []);

  return (
    <>
      <Card sx={{ overflow: 'visible' }}>
        <Box display="flex" alignItems="center" sx={{ pl: 4, pt: 2, pb: 1 }}>
          <Box>
            <Typography variant="h4" noWrap mb={1}>
              {t('users')}
            </Typography>
          </Box>
        </Box>
        <Divider light  />
        <StyledDataGrid
          sx={{
            borderColor: 'primary.light',
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.light',
            },
            '& .MuiDataGrid-table': {
              border: 'none'
            },
            '& .MuiDataGrid-filterForm': {
              color: "#ff0000",
              display: "none",
            }
          }}
          rows={rows}
          columns={columns}
          autoHeight={true}
          loading={tableLoading}
          localeText={{
            toolbarFilters: "Filters",
            toolbarExport: "Export"
          }}
          disableSelectionOnClick
          components={{
            LoadingOverlay: LinearProgress,
          }}
        ></StyledDataGrid>
      </Card>
    </>
  );
}

export default UserLists;