import {Box} from "@mui/material";
import Language from "./Language";
import ReportIssue from "./ReportIssue";
import {useUserAuth} from "../../../../contexts/UserContext";

const HeaderButtons = () => {
    const user = useUserAuth();

    return (
        <Box sx={{mr: 1}}>
            <Box component="span">
                {user.userDetails!.permissions.indexOf('machines') >= 0 && (<ReportIssue/>)}
                <Language/>
            </Box>
        </Box>
    );
};

export default HeaderButtons;