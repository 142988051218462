import { Alert, Autocomplete, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, MenuItem, Modal, Switch, TextField, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import RegularInput from '../../../components/Forms/RegularInput';
import RegularLabel from '../../../components/Forms/RegularLabel';
import ApiService from '../../../services/ApiService';
import * as Yup from "yup";
import { Formik } from 'formik';
import RegularDropdown from '../../../components/Forms/RegularDropdown';
import { Close } from '@mui/icons-material';
import { styleModalXS } from '../../../components/Modal/ModalStyle';
import UserForm from './UserForm';
import { useUserAuth, hasPermission } from '../../../contexts/UserContext';

const YesNoChoice = styled(Switch)(({ theme }) => ({
    padding: 8,
    width: 86,
    height: 46,
    '& .MuiSwitch-track': {
      borderRadius: 50 / 2,
      borderColor: '#fff !important',
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 22,
        height: 22,
        color: '#fff !important',
        fontWeight: 'bold',
      },
      '&:before': {
        content: '"Yes"',
        left: 16,
      },
      '&:after': {
        content: '"No"',
        right: 12,
      },
    },
    '& .MuiButtonBase-root.Mui-checked' : {
        transform: 'translateX(34px)',
    },
    '& .MuiButtonBase-root.Mui-checked  + .MuiSwitch-track' : {
        backgroundColor: '#3BAC2B',
        opacity: 1
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 40,
      height: 31,
      margin: 1,
      borderRadius: 50,
      backgroundColor: '#fff !important',
    },
  }));
  
function OrgForm(props: any) {
    const { t } = useTranslation();
    const { orgID, orgData } = props;
    const isAddMode = !orgID;

    const delay = (ms: any) => new Promise(resolve => setTimeout(resolve, ms));
    const [mainError, setMainError] = useState('');
    const [mainSuccess, setMainSuccess] = useState('');
    const [countries, setCountries] = useState([]);
    const [accOwners, setAccOwners] = useState([]);
    const [orgDetails, setOrgDetails] = useState(!isAddMode ? orgData : { id: 0, company_name: '', street: '', zip_code: '', city: '', telephone: '', mobile: '', fax: '', owner_id: 0, vat: '', tax_rate: 0, countries: [], owners: [], owner_ids: [], is_store:0 });
    const [manageOpeningDaysPopup, setManageOpeningDaysPopup] = useState(false);
    const [stateOpenUsersModal, setOpenUsersModal] = React.useState(false);
    const handleOpenUsersModal = () => { setOpenUsersModal(true); }
    const handleCloseModal = () => { setOpenUsersModal(false); }

    var _validationSchema = Yup.object().shape({
        company_name: Yup.string().required(t('errors.required')),
        street: Yup.string().required(t('errors.required')).max(80),
        zip_code: Yup.string().required(t('errors.required')).max(20),
        city: Yup.string().required(t('errors.required')).max(80),
        country_id: Yup.number().min(1, t('errors.required')),
        owners: Yup.array().min(1, t('errors.required')),
        //countries: Yup.array().min(1, t('errors.required')),
    },
    [["image_file", "image_file"]]);

    useEffect(() => {
        if (orgData.length && orgData.id != 0) {
            setOrgDetails(orgData);
        }

        console.log(orgData.orgs);
        
        (async () => {
            //get the countries
            const respCountries = await ApiService.getCountries();

            if (respCountries.status == 'success') {
                setCountries(respCountries.data);
            }

            //get Account owners
            getUsers();
        })();
    }, []);

    useEffect(() => {
        if (!orgDetails.opening_days) {
            setOrgDetails({...orgDetails, opening_days: ['mo', 'tu', 'we', 'th', 'fr']});
        }
    }, []);

    //get Account owners
    const getUsers = async () => {
        const respOnwers = await ApiService.getUsers({role_id: 2});

        if (respOnwers.status == 'success') {
            setAccOwners(respOnwers.data);
        }
    }

    const handleChangeOpeningDay = (event:any) => {
        const {value, checked} = event.target;

        if (checked) {
            setOrgDetails({...orgDetails, opening_days: [...orgDetails.opening_days, value]});
        } else {
            setOrgDetails({...orgDetails, opening_days: orgDetails.opening_days.filter((x:string) => x !== value)});
        }
    };

    const hitSaveOrg = async (values: any, form: any) => {
        const payload = {...values, opening_days: orgDetails.opening_days};

        const response = isAddMode ? await ApiService.addOrg(payload) : await ApiService.updateOrg(orgID, payload);

        if (response && response.status == 'success') {
            const owners = values.owners.filter((owner:any) => owner.org_id?.toString() === '0' || owner.organization_id?.toString() === '0');

            for (const index in owners) {
                const owner = owners[index];

                await ApiService.updateUserOrganization(owner.id, response.id);
            }

            setMainSuccess(isAddMode ? t('success.new_org_added') : t('success.saved_success'));
            form.setSubmitting(true);
            props.getOrgs();
            await delay(2000);
            props.closeModal();
        } else {
            if (response.message) {
                //console.log(response.error);
                Object.keys(response.message).forEach(function (key: string) {
                    form.setFieldError(key, t('errors.' + response.message[key]));
                });
            }

            if (response.error) {
                Object.keys(response.error).forEach(function (key: string) {
                    form.setFieldError(key, t('errors.' + response.error[key][0]));
                });
            }

            await delay(2000);
            return true;
        }
    }

    const userAuth = useUserAuth();

    return (
        <>
            <Grid container >
                <Grid item xs={12} mb={3}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                        {isAddMode ? t('create_org') : t('edit_org')}
                    </Typography>
                </Grid>                
                {mainError && <Alert onClose={() => { setMainError('') }} sx={{ mb: 2 }} severity="error">{mainError}</Alert>}
                {mainSuccess && <Alert onClose={() => { setMainSuccess('') }} sx={{ mb: 2 }} severity="success">{mainSuccess}</Alert>}

                <Grid item xs={12}>
                    <Formik
                        initialValues={orgDetails}
                        validationSchema={_validationSchema}
                        onSubmit={(values, form) => {
                            return hitSaveOrg(values, form);
                        }}
                        enableReinitialize={true}
                    >
                        {({
                            isSubmitting,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            touched,
                            values,
                            errors,
                            setFieldValue
                        }) => {
                            return <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={9} >
                                        <FormControl fullWidth>
                                            <RegularLabel shrink htmlFor="company_name" variant="standard">
                                                {t('company_name')}*
                                            </RegularLabel>
                                            <RegularInput
                                                fullWidth
                                                type="text"
                                                sx={{ mb: 4 }}
                                                id="company_name"
                                                value={values.company_name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={(errors.company_name && touched.company_name) ? true : false}
                                            />
                                            {errors.company_name && touched.company_name ? <FormHelperText error>{errors.company_name}</FormHelperText> : null}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}></Grid>

                                    <Grid item xs={9} >
                                        <FormControl fullWidth variant="standard" >
                                            <RegularLabel shrink htmlFor="street">
                                                {t('street')}*
                                            </RegularLabel>
                                            <RegularInput
                                                type="text"
                                                fullWidth
                                                sx={{ mb: 4 }}
                                                id="street"
                                                value={values.street}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={(errors.street && touched.street) ? true : false}
                                            />
                                            {errors.street && touched.street ? <FormHelperText error>{errors.street}</FormHelperText> : null}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}></Grid>

                                    <Grid item xs={9} >
                                        <FormControl fullWidth variant="standard" >
                                            <RegularLabel shrink htmlFor="zip_code" >
                                                {t('zip_code')}*
                                            </RegularLabel>
                                            <RegularInput
                                                fullWidth
                                                type="text"
                                                id="zip_code"
                                                value={values.zip_code}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={(errors.zip_code && touched.zip_code) ? true : false}
                                            />
                                            {errors.zip_code && touched.zip_code ? <FormHelperText error>{errors.zip_code}</FormHelperText> : null}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}></Grid>

                                    <Grid item xs={9} >
                                        <FormControl fullWidth variant="standard">
                                            <RegularLabel shrink htmlFor="city" >
                                                {t('city')}*
                                            </RegularLabel>
                                            <RegularInput
                                                fullWidth
                                                type="text"
                                                id="city"
                                                value={values.city}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={(errors.city && touched.city) ? true : false}
                                            />
                                            {errors.city && touched.city ? <FormHelperText error>{errors.city}</FormHelperText> : null}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}></Grid>

                                    <Grid item xs={9} >
                                        <FormControl fullWidth variant="standard">
                                            <RegularLabel shrink htmlFor="country_id" >
                                                {t('country')}*
                                            </RegularLabel>
                                            <RegularDropdown
                                                fullWidth
                                                value={values.country_id}
                                                disableUnderline
                                                onChange={(e) => setFieldValue('country_id', e.target.value as number)}
                                                onBlur={handleBlur}
                                                error={(errors.country_id && touched.country_id) ? true : false}
                                            >
                                                {
                                                    countries && countries.map((country: any) => {
                                                        return <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>
                                                    })
                                                }
                                            </RegularDropdown>
                                            {errors.country_id && touched.country_id ? <FormHelperText error>{errors.country_id}</FormHelperText> : null}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}></Grid>

                                    <Grid item xs={9} >
                                        <FormControl fullWidth variant="standard">
                                            <RegularLabel shrink htmlFor="owners" >{t('account_owners')}*</RegularLabel>
                                            <Autocomplete
                                                multiple
                                                fullWidth
                                                id="owners"
                                                defaultValue={values.owners || orgData.owners}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(event, newValue) => {
                                                    setFieldValue('owners', newValue);
                                                }}
                                                onBlur={handleBlur}
                                                options={accOwners}
                                                sx={{ mt: 2 }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        type="text"
                                                        {...params}
                                                        error={(errors.owners && touched.owners) ? true : false}
                                                    />
                                                )}
                                            />
                                            {errors.owners && touched.owners ? <FormHelperText error>{errors.owners}</FormHelperText> : null}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box >&nbsp;</Box>
                                        <Button variant="contained" fullWidth size='small' sx={{ fontSize: 12 }} onClick={handleOpenUsersModal} >{t('add_user')}</Button>
                                    </Grid>

                                    <Grid item xs={9} >
                                        <FormControl fullWidth >
                                            <RegularLabel shrink htmlFor="telephone" variant="standard">
                                                {t('central_tele')}
                                            </RegularLabel>
                                            <RegularInput
                                                fullWidth
                                                type="text"
                                                sx={{ mb: 4 }}
                                                id="telephone"
                                                value={values.telephone}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={(errors.telephone && touched.telephone) ? true : false}
                                            />
                                            {errors.telephone && touched.telephone ? <FormHelperText error>{errors.telephone}</FormHelperText> : null}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}></Grid>

                                    <Grid item xs={9} >
                                        <FormControl fullWidth variant="standard" >
                                            <RegularLabel shrink htmlFor="fax">
                                                {t('central_fax')}
                                            </RegularLabel>
                                            <RegularInput
                                                type="text"
                                                fullWidth
                                                sx={{ mb: 4 }}
                                                id="fax"
                                                value={values.fax}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={(errors.fax && touched.fax) ? true : false}
                                            />
                                            {errors.fax && touched.fax ? <FormHelperText error>{errors.fax}</FormHelperText> : null}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}></Grid>

                                    <Grid item xs={9} >
                                        <FormControl fullWidth variant="standard" >
                                            <RegularLabel shrink htmlFor="vat">
                                                {t('vat')}
                                            </RegularLabel>
                                            <RegularInput
                                                type="text"
                                                fullWidth
                                                sx={{ mb: 4 }}
                                                id="vat"
                                                value={values.vat}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={(errors.vat && touched.vat) ? true : false}
                                            />
                                            {errors.vat && touched.vat ? <FormHelperText error>{errors.vat}</FormHelperText> : null}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}></Grid>

                                    <Grid item xs={9} >
                                        <FormControl fullWidth variant="standard" >
                                            <RegularLabel shrink htmlFor="tax_rate">
                                                {t('tax_rate')}
                                            </RegularLabel>
                                            <RegularInput
                                                type="text"
                                                fullWidth
                                                sx={{ mb: 4 }}
                                                id="tax_rate"
                                                value={values.tax_rate}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={(errors.tax_rate && touched.tax_rate) ? true : false}
                                            />
                                            {errors.tax_rate && touched.tax_rate ? <FormHelperText error>{errors.tax_rate}</FormHelperText> : null}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}></Grid>

                                    <Grid item xs={9} >
                                        <FormControl fullWidth variant="standard">
                                            <RegularLabel shrink htmlFor="opening_hours">
                                                {t('opening_hours')} <span className="text-lowercase">({t('optional')})</span>
                                            </RegularLabel>
                                            <RegularInput
                                                error={!!(errors.opening_hours && touched.opening_hours)}
                                                value={values.opening_hours}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                id="opening_hours"
                                                sx={{mb: 4}}
                                                type="text"
                                                fullWidth
                                            />
                                            {errors.opening_hours && touched.opening_hours && (<FormHelperText error>
                                                {errors.opening_hours}
                                            </FormHelperText>)}
                                            <Button sx={{mt: 2, mb: .5, width: 'max-content'}} onClick={() => setManageOpeningDaysPopup(true)} variant="contained" size="medium">
                                                {t('manage_opening_days')}
                                            </Button>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}></Grid>

                                    <Grid item xs={9} >
                                        <FormControl fullWidth >
                                            <RegularLabel shrink htmlFor="countries" sx={{ ml: '0 !important' }} >
                                                {t('owned_countries')}
                                            </RegularLabel>
                                            <Autocomplete
                                                multiple
                                                fullWidth
                                                id="countries"
                                                defaultValue={values.countries || orgData.countries}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(event, newValue) => {
                                                    setFieldValue('countries', newValue);
                                                }}
                                                onBlur={handleBlur}
                                                options={countries}
                                                sx={{ mt: 1 }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        type="text"
                                                        {...params}
                                                        error={(errors.countries && touched.countries) ? true : false}
                                                    />
                                                )}
                                            />
                                            {errors.countries && touched.countries ? <FormHelperText error>{errors.countries}</FormHelperText> : null}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}></Grid>

                                    {(userAuth.userDetails && userAuth.userDetails.company_name == 'Krinner Foundation Systems LP') || (hasPermission('roles.add')) ? 
                                    <Grid item xs={9} >
                                        <FormControl fullWidth variant="standard" >
                                            <RegularLabel shrink htmlFor="is_store">
                                                {t('has_store')}
                                            </RegularLabel>
                                            
                                            <FormControlLabel sx={{ml: 0, mt:2}}
                                                value={values.is_store}
                                                id='is_store'
                                                control={<YesNoChoice checked={values.is_store} onChange={(event, newValue) => {
                                                    if(event.target.checked) {
                                                        setFieldValue('is_store', 1);
                                                    } else {
                                                        setFieldValue('is_store', 0);
                                                    }
                                                }} />} 
                                            label="" />
                                            
                                            {errors.is_store && touched.is_store ? <FormHelperText error>{errors.is_store}</FormHelperText> : null}
                                        </FormControl>
                                    </Grid>
                                    : null}
                                    <Grid item xs={3}></Grid>
                                    <Grid item xs={12} >
                                        <Divider light sx={{ my: 3 }} />
                                        <Button variant="contained" type='submit' size='large' disabled={isSubmitting} sx={{ mr: 4 }} >{t('save')}</Button>
                                        <Button variant="outlined" type='button' size='large' onClick={props.closeModal} >{t('close')}</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        }}
                    </Formik>
                </Grid>
            </Grid>
            <Modal
                open={stateOpenUsersModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModalXS}>
                    {stateOpenUsersModal ? (
                        <IconButton
                            aria-label="close"
                            onClick={() => { handleCloseModal(); }}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <Close />
                        </IconButton>
                    ) : null}
                    <UserForm close={handleCloseModal} getUsers={getUsers} />
                </Box>
            </Modal>
            <Modal open={manageOpeningDaysPopup} onClose={() => setManageOpeningDaysPopup(false)}>
                <Box sx={styleModalXS}>
                    <IconButton
                        aria-label="close"
                        onClick={() => setManageOpeningDaysPopup(false)}
                        sx={{top: 8, right: 8, position: 'absolute', color: theme => theme.palette.grey[500]}}
                    ><Close/></IconButton>
                    <Box display="flex" flexDirection="column">
                        {['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'].map(weekday => {
                            return (
                                <label key={weekday} htmlFor={`weekday-${weekday}`} style={{cursor: 'pointer'}}>
                                    <Checkbox
                                        checked={orgDetails.opening_days?.includes(weekday)}
                                        onChange={handleChangeOpeningDay}
                                        id={`weekday-${weekday}`}
                                        value={weekday}
                                    />
                                    <span>{t(`weekdays.${weekday}`)}</span>
                                </label>
                            );
                        })}
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default OrgForm;