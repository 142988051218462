import { useContext, useState } from 'react';
import { ListSubheader, alpha, Box, List, styled, Button, ListItem, Collapse } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from '../../../../contexts/SidebarContext';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import DisplaySettingsTwoToneIcon from '@mui/icons-material/DisplaySettingsTwoTone';
import { BadgeTwoTone, CellTower, ClassOutlined, CorporateFareOutlined, Cyclone, DataThresholdingOutlined, ExpandLessOutlined, ExpandMoreOutlined, FoundationOutlined, Handyman, MonetizationOnOutlined, MonitorHeartOutlined, PersonOutlineTwoTone, PrecisionManufacturing, PrecisionManufacturingSharp, TerminalOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useUserAuth } from '../../../../contexts/UserContext';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(10)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const NestedMenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: 0 0 0 ${theme.spacing(2)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(10)};
    }

    .MuiListItem-root {
      padding: 1px 0;

      .MuiButton-root {
        display: flex;
        color: ${theme.colors.alpha.trueWhite[70]};
        background-color: transparent;
        width: 100%;
        justify-content: flex-start;
        padding: ${theme.spacing(0.5, 0, 0.5, 3)};

        .MuiButton-startIcon,
        .MuiButton-endIcon {
          transition: ${theme.transitions.create(['color'])};

          .MuiSvgIcon-root {
            font-size: inherit;
            transition: none;
          }
        }
    }
  }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .ToggleMenu {
       padding: 0;   
      }

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create(['transform', 'opacity'])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {
                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext)
  const userAuth = useUserAuth();
  const { t } = useTranslation()
  const [machineOpen, setMachineOpen] = useState(false);
  const [orgOpen, setOrgOpen] = useState(false);
  const [projOpen, setProjOpen] = useState(false);
  const [rental, setRental] = useState(false);
  const [screws, setScrews] = useState(false);

  const handleMacMenuToggle = () => {
    setMachineOpen((prev) => !prev);
  };

  const handleOrgMenuToggle = () => {
    setOrgOpen((prev) => !prev);
  };

  const handleProjMenuToggle = () => {
    setProjOpen((prev) => !prev);
  };

  return (
    <>
      <MenuWrapper>
        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/admin/dashboard"
                startIcon={<DesignServicesTwoToneIcon />}
              >
                {t('dashboard')}
              </Button>
            </ListItem>

            {(userAuth.userDetails!.permissions.indexOf('rentals') >= 0 || userAuth.userDetails!.permissions.indexOf('bookable_machines') >= 0) && (<>
              <ListItem component="div" onClick={() => setRental(!rental)}>
                <Button
                    disableRipple
                    startIcon={<MonetizationOnOutlined/>}
                    endIcon={rental ? <ExpandLessOutlined/> : <ExpandMoreOutlined/>}
                >{t('rental_module')}</Button>
              </ListItem>
              <Collapse in={rental} timeout="auto" unmountOnExit component="div">
                <NestedMenuWrapper>
                  <List component="div" disablePadding>
                    {userAuth.userDetails!.permissions.indexOf('rentals') >= 0 && (<ListItem component="div">
                      <Button
                          disableRipple
                          to="/admin/rentals"
                          onClick={closeSidebar}
                          component={RouterLink}
                          startIcon={<MonetizationOnOutlined/>}
                      >{t('rentals')}</Button>
                    </ListItem>)}
                    {userAuth.userDetails!.permissions.indexOf('bookable_machines') >= 0 && (<ListItem component="div">
                      <Button
                          disableRipple
                          onClick={closeSidebar}
                          component={RouterLink}
                          to="/admin/bookable-machines"
                          startIcon={<PrecisionManufacturing/>}
                      >{t('bookable_machines')}</Button>
                    </ListItem>)}
                    {userAuth.userDetails!.permissions.indexOf('book_machine') >= 0 && (<ListItem component="div">
                      <Button
                          disableRipple
                          onClick={closeSidebar}
                          component={RouterLink}
                          to="/admin/book-machine"
                          startIcon={<PrecisionManufacturing/>}
                      >{t('book_machine')}</Button>
                    </ListItem>)}
                  </List>
                </NestedMenuWrapper>
              </Collapse>
            </>)}

            {userAuth.userDetails!.permissions.indexOf('screws') >= 0 && (<>
              <ListItem component="div" onClick={() => setScrews(!screws)}>
                <Button
                    disableRipple
                    startIcon={<Handyman/>}
                    endIcon={screws ? <ExpandLessOutlined/> : <ExpandMoreOutlined/>}
                >{t('screw_management')}</Button>
              </ListItem>
              <Collapse in={screws} timeout="auto" unmountOnExit component="div">
                <NestedMenuWrapper>
                  <List component="div" disablePadding>
                    <ListItem component="div">
                      <Button
                          disableRipple
                          to="/admin/screws"
                          onClick={closeSidebar}
                          component={RouterLink}
                          startIcon={<Handyman/>}
                      >{t('screws')}</Button>
                    </ListItem>
                  </List>
                </NestedMenuWrapper>
              </Collapse>
            </>)}

            {(userAuth.userDetails!.permissions.indexOf('projects') >= 0 || userAuth.userDetails!.permissions.indexOf('datasets') >= 0) &&
              <><ListItem component="div" onClick={handleProjMenuToggle}>
                <Button
                  disableRipple
                  startIcon={<FoundationOutlined />}
                  endIcon={projOpen ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                >
                  {t('project_management')}
                </Button>
              </ListItem>
                <Collapse in={projOpen} timeout="auto" unmountOnExit component="div">
                  <NestedMenuWrapper>
                    <List component="div" disablePadding>
                      {(userAuth.userDetails!.permissions.indexOf('projects') >= 0) &&
                        <ListItem component="div">
                          <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to="/admin/projects"
                            startIcon={<FoundationOutlined />}
                          >
                            {t('projects')}
                          </Button>
                        </ListItem>}
                      {userAuth.userDetails!.permissions.indexOf('datasets') >= 0 &&
                        <ListItem component="div">
                          <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to="/admin/datasets"
                            startIcon={<DataThresholdingOutlined />}
                          >
                            {t('datasets')}
                          </Button>
                        </ListItem>}
                    </List>
                  </NestedMenuWrapper>
                </Collapse>
              </>}

            {(userAuth.userDetails!.permissions.indexOf('machines') >= 0 || userAuth.userDetails!.permissions.indexOf('torqueHeads') >= 0) &&
              <>
                <ListItem component="div" className="ToggleMenu" onClick={handleMacMenuToggle}>
                  <Button
                    disableRipple
                    startIcon={<PrecisionManufacturing />}
                    endIcon={machineOpen ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                  >
                    {t('machine_management')}
                  </Button>
                </ListItem>
                <Collapse in={machineOpen} timeout="auto" unmountOnExit component="div">
                  <NestedMenuWrapper>
                    <List component="div">
                      {userAuth.userDetails!.permissions.indexOf('machines') >= 0 &&
                        <ListItem component="div">
                          <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to="/admin/machines"
                            startIcon={<PrecisionManufacturing />}
                          >
                            {t('machines')}
                          </Button>
                        </ListItem>}
                      {userAuth.userDetails!.permissions.indexOf('machines') >= 0 &&
                        <ListItem component="div">
                          <Button
                            disableRipple
                            onClick={closeSidebar}
                            component={RouterLink}
                            startIcon={<Handyman/>}
                            to="/admin/machine-issues"
                          >
                            {t('machine_issues')}
                          </Button>
                        </ListItem>
                      }
                      {userAuth.userDetails!.permissions.indexOf('torqueHeads') >= 0 &&
                        <ListItem component="div">
                          <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to="/admin/torqueHeads"
                            startIcon={<Cyclone />}
                          >
                            {t('torque_head')}
                          </Button>
                        </ListItem>}
                      {userAuth.userDetails!.permissions.indexOf('sensors') >= 0 &&
                        <ListItem component="div">
                          <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to="/admin/sensors"
                            startIcon={<CellTower />}
                          >
                            {t('sensors')}
                          </Button>
                        </ListItem>}
                      {userAuth.userDetails!.permissions.indexOf('carriers') >= 0 &&
                        <ListItem component="div">
                          <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to="/admin/carriers"
                            startIcon={<PrecisionManufacturingSharp />}
                          >
                            {t('carriers')}
                          </Button>
                        </ListItem>}
                      {userAuth.userDetails!.permissions.indexOf('recordingDevices') >= 0 &&
                        <ListItem component="div">
                          <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to="/admin/recording_devices"
                            startIcon={<MonitorHeartOutlined />}
                          >
                            {t('rec_devices')}
                          </Button>
                        </ListItem>}
                      {userAuth.userDetails!.permissions.indexOf('firmwares') >= 0 &&
                        <ListItem component="div">
                          <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to="/admin/firmwares"
                            startIcon={<TerminalOutlined />}
                          >
                            {t('firmwares')}
                          </Button>
                        </ListItem>}
                        {userAuth.userDetails!.permissions.indexOf('licenses') >= 0 &&
                        <ListItem component="div">
                          <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to="/admin/licenses"
                            startIcon={<ClassOutlined />}
                          >
                            {t('licenses')}
                          </Button>
                        </ListItem>}
                    </List>
                  </NestedMenuWrapper>
                </Collapse>
              </>
            }
            {(userAuth.userDetails!.permissions.indexOf('admin.users') >= 0 || userAuth.userDetails!.permissions.indexOf('organizations') >= 0 || userAuth.userDetails!.permissions.indexOf('roles') >= 0) &&
              <>
                {userAuth.userDetails!.permissions.indexOf('organizations') >= 0 &&
                  <><ListItem component="div" onClick={handleOrgMenuToggle}>
                    <Button
                      disableRipple
                      startIcon={<CorporateFareOutlined />}
                      endIcon={orgOpen ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                    >
                      {t('organization_management')}
                    </Button>
                  </ListItem>
                    <Collapse in={orgOpen} timeout="auto" unmountOnExit component="div">
                      <NestedMenuWrapper>
                        <List component="div" disablePadding>
                          <ListItem component="div">
                            <Button
                              disableRipple
                              component={RouterLink}
                              onClick={closeSidebar}
                              to="/admin/organizations"
                              startIcon={<CorporateFareOutlined />}
                            >
                              {t('organizations')}
                            </Button>
                          </ListItem>
                          {userAuth.userDetails!.permissions.indexOf('admin.users') >= 0 &&
                            <ListItem component="div">
                              <Button
                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to="/admin/users"
                                startIcon={<PersonOutlineTwoTone />}
                              >
                                {t('users')}
                              </Button>
                            </ListItem>}
                          {userAuth.userDetails!.permissions.indexOf('roles') >= 0 &&
                            <ListItem component="div">
                              <Button
                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to="/admin/roles"
                                startIcon={<BadgeTwoTone />}
                              >
                                {t('roles')}
                              </Button>
                            </ListItem>}
                        </List>
                      </NestedMenuWrapper>
                    </Collapse>
                  </>}
              </>
            }
          </List>
        </SubMenuWrapper>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              {t('settings')}
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/admin/profile"
                  startIcon={<AccountCircleTwoToneIcon />}
                >
                  {t('profile')}
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
      </MenuWrapper >
    </>
  );
}

export default SidebarMenu;
