import { any, number } from "prop-types";

const apiDomain = process.env.REACT_APP_API_URL;

class UrlService {
    //Auth Urls
    static loginUrl() {
        return apiDomain + "auth/login";
    }

    //logout Url
    static logoutUrl() {
        return apiDomain + "user/logout";
    }

    //Request reset password
    static resetPassUrl() {
        return apiDomain + "auth/request_reset_password";
    }

    //Validate reset password request
    static validateResetTokenUrl() {
        return apiDomain + "auth/validate_reset_password_token";
    }

    //Validate reset password request
    static verifyRegistrationUrl() {
        return apiDomain + "auth/verify_registration_token";
    }

    //Validate browser token
    static verifyAuthToken() {
        return apiDomain + "auth/verify_token";
    }

    //Logout from server
    static logout() {
        return apiDomain + "auth/logout";
    }

    //Update password
    static updatePasswordUrl() {
        return apiDomain + "auth/reset_password";
    }

    //Admin side urls
    static adminGetUsers() {
        return apiDomain + "users";
    }

    static adminGetUserByID(id: any) {
        return apiDomain + "user/get/" + id;
    }

    static adminGetRoleNames() {
        return apiDomain + "roles/names";
    }

    static adminAddNewUser() {
        return apiDomain + "user/add";
    }

    static adminUpdateUser(userID: string | undefined) {
        return apiDomain + "user/update/" + userID;
    }

    static updateUserOrganization(user_id: number) {
        return apiDomain + `user/update-organization/${user_id}`;
    }

    static adminDeleteUser(userID: string | undefined) {
        return apiDomain + "user/delete/" + userID;
    }

    static adminSetUserStatus(userID: string | undefined) {
        return apiDomain + "user/set_status/" + userID;
    }

    static adminResendNewPassword() {
        return apiDomain + "user/resend_password";
    }

    static adminGetRoles() {
        return apiDomain + "roles";
    }

    static adminAddRole() {
        return apiDomain + "role/add";
    }

    static adminUpdateRole(roleID: string | undefined) {
        return apiDomain + "role/update/" + roleID;
    }

    static adminGetPermissions() {
        return apiDomain + "permissions";
    }

    static adminDeleteRole(roleID: string | undefined) {
        return apiDomain + "role/delete/" + roleID;
    }

    static adminGetScrews() {
        return apiDomain + "screws";
    }

    static adminGetScrewSerieses() {
        return apiDomain + "screw/serieses";
    }

    static getRegions() {
        return apiDomain + "regions";
    }

    static addRegion() {
        return apiDomain + "region/add";
    }

    static addScrew() {
        return apiDomain + "screw/add";
    }

    static updateScrew(screwID: string | undefined) {
        return apiDomain + "screw/update/" + screwID;
    }

    static deleteScrew(screwID: string | undefined) {
        return apiDomain + "screw/delete/" + screwID;
    }

    static setScrewStatus(screwID: string | undefined) {
        return apiDomain + "screw/set_status/" + screwID;
    }

    static getCountries() {
        return apiDomain + "countries";
    }

    static findStatistics(name: string) {
        return apiDomain + `statistics/${name}`;
    }

    static getOrgs() {
        return apiDomain + "organizations";
    }

    static getOrgNames() {
        return apiDomain + "organizations/names";
    }

    static deleteOrg(orgID: string | undefined) {
        return apiDomain + "organization/delete/" + orgID;
    }

    static setOrgStatus(orgID: string | undefined) {
        return apiDomain + "organization/set_status/" + orgID;
    }

    static addOrg() {
        return apiDomain + "organization/add";
    }

    static updateOrg(orgID: string | undefined) {
        return apiDomain + "organization/update/" + orgID;
    }

    static getTorqueHeads() {
        return apiDomain + "torqueHeads";
    }

    static getTorqueHeadTypes() {
        return apiDomain + "torqueHeadTypes";
    }

    static addTorqueHead() {
        return apiDomain + "torqueHead/add";
    }

    static addTorqueHeadType() {
        return apiDomain + "torqueHeadType/add";
    }

    static updateTorqueHead(tqhID: string | undefined) {
        return apiDomain + "torqueHead/update/" + tqhID;
    }

    static setTorqueHeadStatus(tqhID: string | undefined) {
        return apiDomain + "torqueHead/set_status/" + tqhID;
    }

    static deleteTorqueHead(tqhID: string | undefined) {
        return apiDomain + "torqueHead/delete/" + tqhID;
    }

    static getSensors() {
        return apiDomain + "sensors";
    }

    static getSensorTypes() {
        return apiDomain + "sensorTypes";
    }

    static addSensor() {
        return apiDomain + "sensor/add";
    }

    static addSensorType() {
        return apiDomain + "sensorType/add";
    }

    static updateSensor(sensorID: string | undefined) {
        return apiDomain + "sensor/update/" + sensorID;
    }

    static setSensorStatus(sensorID: string | undefined) {
        return apiDomain + "sensor/set_status/" + sensorID;
    }

    static deleteSensor(sensorID: string | undefined) {
        return apiDomain + "sensor/delete/" + sensorID;
    }


    static getCarriers() {
        return apiDomain + "carriers";
    }

    static getCarrierTypes() {
        return apiDomain + "carrierTypes";
    }

    static addCarrier() {
        return apiDomain + "carrier/add";
    }

    static addCarrierType() {
        return apiDomain + "carrierType/add";
    }

    static updateCarrier(carrierID: string | undefined) {
        return apiDomain + "carrier/update/" + carrierID;
    }

    static setCarrierStatus(carrierID: string | undefined) {
        return apiDomain + "carrier/set_status/" + carrierID;
    }

    static deleteCarrier(carrierID: string | undefined) {
        return apiDomain + "carrier/delete/" + carrierID;
    }

    static getRecDevices() {
        return apiDomain + "recording_devices";
    }

    static getRecDeviceTypes() {
        return apiDomain + "recording_device_types";
    }

    static addRecDevice() {
        return apiDomain + "recording_device/add";
    }

    static addRecDeviceType() {
        return apiDomain + "recording_device_type/add";
    }

    static updateRecDevice(recDeviceID: string | undefined) {
        return apiDomain + "recording_device/update/" + recDeviceID;
    }

    static setRecDeviceStatus(recDeviceID: string | undefined) {
        return apiDomain + "recording_device/set_status/" + recDeviceID;
    }

    static deleteRecDevice(recDeviceID: string | undefined) {
        return apiDomain + "recording_device/delete/" + recDeviceID;
    }

    static uploadRdFiles(recDeviceID: string | undefined) {
        return apiDomain + "recording_device/upload_file/" + recDeviceID;
    }


    static getFirmwares() {
        return apiDomain + "firmwares";
    }

    static getFirmwaresFiltered(typeID: string | undefined) {
        //console.log("typeID");
        //console.log(typeID);
        return apiDomain + "firmwares?type=" + typeID;
    }

    static addFirmware() {
        return apiDomain + "firmware/add";
    }

    static updateFirmware(firmwareID: string | undefined) {
        return apiDomain + "firmware/update/" + firmwareID;
    }

    static setFirmwareStatus(firmwareID: string | undefined) {
        return apiDomain + "firmware/set_status/" + firmwareID;
    }

    static deleteFirmware(firmwareID: string | undefined) {
        return apiDomain + "firmware/delete/" + firmwareID;
    }

    static getFirmwareLog(firmwareID: string | undefined) {
        return apiDomain + "firmware/logs/" + firmwareID;
    }

    static getMachines() {
        return apiDomain + "machines";
    }

    // Carrier, Torque Head, Recording Device, Sensors etc. data arrays for machine
    // This will send filtered data of above records which are not assigned to any machine
    static getMachineCTRSData(machineID: string | undefined) {
        return apiDomain + "machines/ctrs_data/" + machineID;
    }

    static addMachine() {
        return apiDomain + "machine/add";
    }

    static updateMachine(machineID: string | undefined) {
        return apiDomain + "machine/update/" + machineID;
    }

    static setMachineStatus(machineID: string | undefined) {
        return apiDomain + "machine/set_status/" + machineID;
    }

    static deleteMachine(machineID: string | undefined) {
        return apiDomain + "machine/delete/" + machineID;
    }

    static getProjects() {
        return apiDomain + "projects";
    }

    static getProjectByID(id: string) {
        return `${apiDomain}project/get/${id}`;
    }

    static deleteProject(projID: string | undefined) {
        return apiDomain + "project/delete/" + projID;
    }

    static addProject() {
        return apiDomain + "project/add";
    }

    static updateProject(projID: string | undefined) {
        return apiDomain + "project/update/" + projID;
    }

    static setProjectStatus(projID: string | undefined) {
        return apiDomain + "project/set_status/" + projID;
    }

    static getProjectPositions(projID: string | undefined) {
        return apiDomain + "project/positions/" + projID;
    }

    static getProjectScrews(projID: string | undefined) {
        return apiDomain + "project/screws/" + projID;
    }

    static addProjectScrews(projID: string | undefined) {
        return apiDomain + "project/screws/add/" + projID;
    }

    static addProjectPositions(projID: string | undefined) {
        return apiDomain + "project/positions/add/" + projID;
    }

    static getGraphs(dsID: string | undefined) {
        return apiDomain + "project/graphs/" + dsID;
    }

    static getSummary(projID: string | undefined) {
        return apiDomain + "project/reports/" + projID;
    }

    static generateReport(projID: string | undefined) {
        return apiDomain + "project/report/generate/" + projID;
    }

    static uploadReport(projID: string | undefined) {
        return apiDomain + "project/report/upload/" + projID;
    }

    static uploadProjectDoc(projID: string | undefined) {
        return apiDomain + "project/document/upload/" + projID;
    }

    static excelParse() {
        return apiDomain + "excel/parse";
    }

    static importPositions(projID: string | undefined) {
        return apiDomain + "project/positions/import/" + projID;
    }

    static getProjectDocs(projID: string | undefined) {
        return apiDomain + "project/documents/" + projID;
    }

    static getOrganizationContacts() {
        return apiDomain + "organization/contacts";
    }

    static getProfile() {
        return apiDomain + "user/profile";
    }

    static saveProfile() {
        return apiDomain + "user/profile/save";
    }

    static deleteProfile() {
        return apiDomain + "user/profile/delete";
    }


    static getUserLoginHistory() {
        return apiDomain + "user/profile/login_history";
    }

    static saveProfilePassword() {
        return apiDomain + "user/profile/save_password";
    }

    static getDatasets() {
        return apiDomain + "datasets";
    }

    static getDatasetGroup() {
        return apiDomain + "datasets/groups";
    }

    static getDataset(dsID: string | undefined) {
        return apiDomain + "dataset/get/" + dsID;
    }

    static updateDataset(dsID: string | undefined) {
        return apiDomain + "dataset/update/" + dsID;
    }

    static exportDataset(dsID: string | undefined) {
        return apiDomain + "dataset/export/" + dsID;
    }

    static setDatasetStatus(dsID: string | undefined) {
        return apiDomain + "dataset/set_status/" + dsID;
    }

    static getDatasetGraph(dsID: string | undefined) {
        return apiDomain + "dataset/graph/"+ dsID;
    }

    static proxyUrl() {
        return apiDomain + "html2canvasproxy.php";
    }

    static fetchProjectDatasets(id: string | undefined) {
        return apiDomain + `project/${id}/datasets`;
    }

    static getProjectDatasetPositions(dsID: string | undefined) {
        return apiDomain + "project/dataset/positions/" + dsID;
    }

    static getNewsFeed() {
        return apiDomain + "news/feed";
    }

    static getSingleNews(newsID: string | undefined) {
        return apiDomain + "news/feed/" + newsID;
    }

    static getDashboardSettings() {
        return apiDomain + "settings/dashboard";
    }

    static saveDashboardSettings() {
        return apiDomain + "settings/dashboard/save";
    }

    static updateDatasetData(id: string) {
        return apiDomain + `dataset/${id}/update-data`;
    }

    static bulkDeleteDatasetData(id: string) {
        return apiDomain + `dataset/${id}/bulk-delete`;
    }

    static getReports() {
        return apiDomain + "project/all_reports";
    }

    static getLicenses() {
        return apiDomain + "licenses";
    }

    static getLicenseTypes() {
        return apiDomain + "license/types";
    }

    static addLicense() {
        return apiDomain + "license/add";
    }

    static addLicenseType() {
        return apiDomain + "license/type/add";
    }

    static updateLicense(licenseID: string | undefined) {
        return apiDomain + "license/update/" + licenseID;
    }

    static setLicenseStatus(licenseID: string | undefined) {
        return apiDomain + "license/set_status/" + licenseID;
    }

    static deleteLicense(licenseID: string | undefined) {
        return apiDomain + "license/delete/" + licenseID;
    }

    static getRentals() {
        return apiDomain + "rentals";
    }

    static storeAddRental() {
        return apiDomain + "store/rental/add";
    }

    static deleteRental(rentID: string | undefined) {
        return apiDomain + "rental/delete/" + rentID;
    }

    static getMachineBookings(machineID: number | undefined) {
        return apiDomain + "rental/machine/bookings/" + machineID;
    }

    static setRentalStatus(rentalID: number | undefined) {
        return apiDomain + "rental/set_status/" + rentalID;
    }

    static getRentalStatusLog(rentalID: number | undefined) {
        return apiDomain + "rental/get_status/" + rentalID;
    }

    static getRentalPrices() {
        return apiDomain + "rental/prices";
    }

    static retrieveAvailableProjects() {
        return apiDomain + `user/available/projects`;
    }

    static getMachineIssues() {
        return apiDomain + `machine-issue`;
    }

    static storeMachineIssue() {
        return apiDomain + `machine-issue`;
    }

    static updateMachineIssue(id: number) {
        return apiDomain + `machine-issue/${id}`;
    }

    static destroyMachineIssue(id: number) {
        return apiDomain + `machine-issue/${id}`;
    }

    static storeMachineIssueComment() {
        return apiDomain + `machine-issue-comment`;
    }

    static exportPositions(id: any) {
        return apiDomain + `project/positions/export/${id}`;
    }

    static bookMachine(id: number) {
        return apiDomain + `machine/book/${id}`;
    }
}

export default UrlService;