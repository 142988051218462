import axios from 'axios';
import UrlService from './UrlService';

class ApiService {

  authAxios = axios.create();

  async post(reqUrl: string, data: any, headers?: any) {
    let result: any;
    var validResponse = false;
    if (headers) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('_kcauthtoken')}`;
    } else {
      headers = { Authorization: `Bearer ${localStorage.getItem('_kcauthtoken')}` }
    }

    await this.authAxios.post(reqUrl, data, {
      headers: headers,
      //withCredentials: true,
      validateStatus: function (status) {
        //unauthorized
        if (status == 401) {

        }

        return status < 500;
      }
    })
      .then((response: any) => {
        result = response.data;
        validResponse = true;
      })
      .catch(function (error) {
        console.error('Error', error.response);
      });

    if (validResponse)
      return result;
    else
      return false;
  }

  async delete(reqUrl: string, data: any) {
    let result: any;
    var validResponse = false;

    await this.authAxios.delete(reqUrl, {
      headers: { Authorization: `Bearer ${localStorage.getItem('_kcauthtoken')}` },
      validateStatus: function (status) {
        //unauthorized
        if (status == 401) {

        }

        return status < 500;
      },
      data
    })
      .then((response: any) => {
        result = response.data;
        validResponse = true;
      })
      .catch(function (error) {
        console.error('Error', error.response);
      });

    if (validResponse)
      return result;
    else
      return false;
  }

  async get(reqUrl: string, data: any) {
    let result: any;
    var validResponse = false;

    if (data) {
      var reqParams = new URLSearchParams(data).toString();
      reqUrl += '?' + reqParams;
    }

    await this.authAxios.get(reqUrl, { headers: { Authorization: `Bearer ${localStorage.getItem('_kcauthtoken')}` } })
      .then((response: any) => {
        result = response.data;
        validResponse = true;
      })
      .catch(function (error) {
        console.error('Error', error.response);
      });

    if (validResponse)
      return result;
    else
      return false;
  }

  async verifyAuthToken() {
    return this.post(UrlService.verifyAuthToken(), {});
  }

  async logout() {
    return this.post(UrlService.logout(), {});
  }

  async getUsers(search: any = {}) {
    return this.get(UrlService.adminGetUsers(), search);
  }

  async getUser(userID: any) {
    return this.get(UrlService.adminGetUserByID(userID), {});
  }

  async getRoleNames() {
    return this.get(UrlService.adminGetRoleNames(), {});
  }

  async addUser(values: any) {
    return this.post(UrlService.adminAddNewUser(), values);
  }

  async updateUser(values: any, userID: string | undefined) {
    return this.post(UrlService.adminUpdateUser(userID), values);
  }

  async updateUserOrganization(user_id: number, organization_id: number) {
    return this.post(UrlService.updateUserOrganization(user_id), {organization_id});
  }

  async setUserStatus(userID: string | undefined, newStatus: string) {
    return this.post(UrlService.adminSetUserStatus(userID), { status: newStatus });
  }

  async resendNewPassword(userID: string | undefined) {
    return this.post(UrlService.adminResendNewPassword(), { user_id: userID });
  }

  async deleteUser(userID: string | undefined, extraParmas: any | {}) {
    return await this.delete(UrlService.adminDeleteUser(userID), extraParmas);
  }

  async getRoles() {
    return this.get(UrlService.adminGetRoles(), {});
  }

  async addRole(values: any) {
    return this.post(UrlService.adminAddRole(), values);
  }

  async updateRole(values: any, roleID: string | undefined) {
    return this.post(UrlService.adminUpdateRole(roleID), values);
  }

  async deleteRole(roleID: string | undefined) {
    return await this.delete(UrlService.adminDeleteRole(roleID), {});
  }

  async getPermissions() {
    return this.get(UrlService.adminGetPermissions(), {});
  }

  async getScrews(search: any = {}) {
    return this.get(UrlService.adminGetScrews(), search);
  }

  async getScrewSerieses() {
    return this.get(UrlService.adminGetScrewSerieses(), {});
  }

  async getRegions() {
    return this.get(UrlService.getRegions(), {});
  }

  async addRegion(values: any) {
    return this.post(UrlService.addRegion(), values);
  }

  async addScrew(values: any, form: any) {
    const formData = new FormData();

    for (var key in values) {
      formData.append(key, values[key]);
    }

    return this.post(UrlService.addScrew(), formData, { "Content-Type": "multipart/form-data" });
  }

  async deleteScrew(roleID: string | undefined) {
    return await this.delete(UrlService.deleteScrew(roleID), {});
  }

  async updateScrew(screwID: any, values: any, form: any) {
    const formData = new FormData();

    for (var key in values) {
      formData.append(key, values[key]);
    }

    return this.post(UrlService.updateScrew(screwID), formData, { "Content-Type": "multipart/form-data" });
  }

  async setScrewStatus(userID: string | undefined, newStatus: string) {
    return this.post(UrlService.setScrewStatus(userID), { status: newStatus });
  }

  async getCountries() {
    return this.get(UrlService.getCountries(), {});
  }

  async findStatistics(name: string) {
    return this.post(UrlService.findStatistics(name), {});
  }

  async getOrgs(params: any = {}) {
    return this.get(UrlService.getOrgs(), params);
  }

  async getOrgNames() {
    return this.get(UrlService.getOrgNames(), {});
  }

  async deleteOrg(orgID: string | undefined) {
    return await this.delete(UrlService.deleteOrg(orgID), {});
  }

  async setOrgStatus(orgID: string | undefined, newStatus: string) {
    return this.post(UrlService.setOrgStatus(orgID), { status: newStatus });
  }

  async addOrg(values: any) {
    return this.post(UrlService.addOrg(), values);
  }

  async updateOrg(orgID: string | undefined, values: any) {
    return this.post(UrlService.updateOrg(orgID), values);
  }

  async getTorqueHeads() {
    return this.get(UrlService.getTorqueHeads(), {});
  }

  async getTorqueHeadTypes() {
    return this.get(UrlService.getTorqueHeadTypes(), {});
  }

  async addTorqueHead(values: any) {
    const formData = new FormData();

    for (var key in values) {
      formData.append(key, values[key]);
    }

    return this.post(UrlService.addTorqueHead(), values, { "Content-Type": "multipart/form-data" });
  }

  async addTorqueHeadType(values: any) {
    return this.post(UrlService.addTorqueHeadType(), values);
  }

  async updateTorqueHead(thID: string | undefined, values: any) {
    const formData = new FormData();

    for (var key in values) {
      formData.append(key, values[key]);
    }

    return this.post(UrlService.updateTorqueHead(thID), values, { "Content-Type": "multipart/form-data" });
  }

  async deleteTorqueHead(thID: string | undefined) {
    return await this.delete(UrlService.deleteTorqueHead(thID), {});
  }

  async setTorqueHeadStatus(thID: string | undefined, newStatus: string) {
    return this.post(UrlService.setTorqueHeadStatus(thID), { status: newStatus });
  }

  async getSensors() {
    return this.get(UrlService.getSensors(), {});
  }

  async getSensorTypes() {
    return this.get(UrlService.getSensorTypes(), {});
  }

  async setSensorStatus(thID: string | undefined, newStatus: string) {
    return this.post(UrlService.setSensorStatus(thID), { status: newStatus });
  }

  async addSensor(values: any) {
    const formData = new FormData();

    for (var key in values) {
      formData.append(key, values[key]);
    }

    return this.post(UrlService.addSensor(), values, { "Content-Type": "multipart/form-data" });
  }

  async addSensorType(values: any) {
    return this.post(UrlService.addSensorType(), values);
  }

  async updateSensor(thID: string | undefined, values: any) {
    const formData = new FormData();

    for (var key in values) {
      formData.append(key, values[key]);
    }

    return this.post(UrlService.updateSensor(thID), values, { "Content-Type": "multipart/form-data" });
  }

  async deleteSensor(thID: string | undefined) {
    return await this.delete(UrlService.deleteSensor(thID), {});
  }


  async getCarriers() {
    return this.get(UrlService.getCarriers(), {});
  }

  async getCarrierTypes() {
    return this.get(UrlService.getCarrierTypes(), {});
  }

  async setCarrierStatus(carrierID: string | undefined, newStatus: string) {
    return this.post(UrlService.setCarrierStatus(carrierID), { status: newStatus });
  }

  async addCarrier(values: any) {
    const formData = new FormData();

    for (var key in values) {
      formData.append(key, values[key]);
    }

    return this.post(UrlService.addCarrier(), values, { "Content-Type": "multipart/form-data" });
  }

  async addCarrierType(values: any) {
    return this.post(UrlService.addCarrierType(), values);
  }

  async updateCarrier(carrierID: string | undefined, values: any) {
    const formData = new FormData();

    for (var key in values) {
      formData.append(key, values[key]);
    }

    return this.post(UrlService.updateCarrier(carrierID), values, { "Content-Type": "multipart/form-data" });
  }

  async deleteCarrier(carrierID: string | undefined) {
    return await this.delete(UrlService.deleteCarrier(carrierID), {});
  }


  async getRecDevices() {
    return this.get(UrlService.getRecDevices(), {});
  }

  async getRecDeviceTypes() {
    return this.get(UrlService.getRecDeviceTypes(), {});
  }

  async setRecDeviceStatus(carrierID: string | undefined, newStatus: string) {
    return this.post(UrlService.setRecDeviceStatus(carrierID), { status: newStatus });
  }

  async addRecDevice(values: any) {
    const formData = new FormData();

    for (var key in values) {
      formData.append(key, values[key]);
    }

    return this.post(UrlService.addRecDevice(), values, { "Content-Type": "multipart/form-data" });
  }

  async addRecDeviceType(values: any) {
    return this.post(UrlService.addRecDeviceType(), values);
  }

  async updateRecDevice(carrierID: string | undefined, values: any) {
    const formData = new FormData();

    for (var key in values) {
      formData.append(key, values[key]);
    }

    return this.post(UrlService.updateRecDevice(carrierID), values, { "Content-Type": "multipart/form-data" });
  }

  async deleteRecDevice(carrierID: string | undefined) {
    return await this.delete(UrlService.deleteRecDevice(carrierID), {});
  }

  async uploadRdFile(thID: string | undefined, values: any) {
    const formData = new FormData();

    for (var key in values) {
      formData.append(key, values[key]);
    }

    return this.post(UrlService.uploadRdFiles(thID), values, { "Content-Type": "multipart/form-data" });
  }

  async getFirmwares() {
    return this.get(UrlService.getFirmwares(), {});
  }

  async getFirmwareLog(firmwareID: string | undefined, params: any) {
    return this.get(UrlService.getFirmwareLog(firmwareID), params);
  }

  async getFirmwaresFiltered(typeID: string | undefined) {
    return this.get(UrlService.getFirmwares(), { 'type': typeID });
  }

  async setFirmwareStatus(carrierID: string | undefined, newStatus: string) {
    return this.post(UrlService.setFirmwareStatus(carrierID), { status: newStatus });
  }

  async addFirmware(values: any) {
    const formData = new FormData();

    for (var key in values) {
      formData.append(key, values[key]);
    }

    return this.post(UrlService.addFirmware(), values, { "Content-Type": "multipart/form-data" });
  }

  async updateFirmware(carrierID: string | undefined, values: any) {
    const formData = new FormData();

    for (var key in values) {
      formData.append(key, values[key]);
    }

    return this.post(UrlService.updateFirmware(carrierID), values, { "Content-Type": "multipart/form-data" });
  }

  async deleteFirmware(carrierID: string | undefined) {
    return await this.delete(UrlService.deleteFirmware(carrierID), {});
  }

  async getMachines(params: any = {}) {
    return this.get(UrlService.getMachines(), params);
  }

  async getMachineCTRSData(machineID: string | undefined,) {
    return this.get(UrlService.getMachineCTRSData(machineID), {});
  }

  async setMachineStatus(machineID: string | undefined, newStatus: string) {
    return this.post(UrlService.setMachineStatus(machineID), { status: newStatus });
  }

  async addMachine(values: any) {
    /*const formData = new FormData();

    for ( var key in values ) {
      formData.append(key, values[key]);
    }*/

    return this.post(UrlService.addMachine(), values);
  }

  async updateMachine(machineID: string | undefined, values: any) {
    /*const formData = new FormData();

    for ( var key in values ) {
      formData.append(key, values[key]);
    }*/

    return this.post(UrlService.updateMachine(machineID), values);
  }

  async deleteMachine(machineID: string | undefined) {
    return await this.delete(UrlService.deleteMachine(machineID), {});
  }

  async getProjects() {
    return this.get(UrlService.getProjects(), {});
  }

  async getProjectByID(id: string) {
    return this.get(UrlService.getProjectByID(id), {});
  }

  async deleteProject(projID: string | undefined) {
    return await this.delete(UrlService.deleteProject(projID), {});
  }

  async addProject(values: any) {
    return this.post(UrlService.addProject(), values);
  }

  async updateProject(projID: string | undefined, values: any) {
    return this.post(UrlService.updateProject(projID), values);
  }

  async setProjectStatus(projID: string | undefined, newStatus: string) {
    return this.post(UrlService.setProjectStatus(projID), { status: newStatus });
  }

  async getProjectPositions(projID: string | undefined) {
    return this.get(UrlService.getProjectPositions(projID), {});
  }

  async getProjectScrews(projID: string | undefined) {
    return this.get(UrlService.getProjectScrews(projID), {});
  }

  async addProjectScrews(projID: string | undefined, values: any) {
    return this.post(UrlService.addProjectScrews(projID), values);
  }

  async addProjectPositions(projID: string | undefined, values: any) {
    return this.post(UrlService.addProjectPositions(projID), values);
  }

  async generateReport(projID: string | undefined, values: any) {
    /*const formData = new FormData();

    for (var key in values) {
      formData.append(key, values[key]);
    }*/

    return this.post(UrlService.generateReport(projID), values, { "Content-Type": "multipart/form-data" });
  }

  async getGraphs(dsID: string | undefined) {
    return this.get(UrlService.getGraphs(dsID), {});
  }

  async getSummary(projID: string | undefined) {
    return this.get(UrlService.getSummary(projID), {});
  }

  async getOrganizationContacts() {
    return this.get(UrlService.getOrganizationContacts(), {});
  }

  async getProfile() {
    return this.get(UrlService.getProfile(), {});
  }

  async saveProfile(values: any) {
    return this.post(UrlService.saveProfile(), values);
  }

  async deleteProfile() {
    return await this.delete(UrlService.deleteProfile(), {});
  }

  async getUserLoginHistory() {
    return this.get(UrlService.getUserLoginHistory(), {});
  }

  async saveProfilePassword(values: any) {
    return this.post(UrlService.saveProfilePassword(), values);
  }

  async getDatasets(search: any) {
    return this.get(UrlService.getDatasets(), search);
  }

  async getDataset(dsID: string | undefined) {
    return this.get(UrlService.getDataset(dsID), {});
  }

  async getDatasetGroup(params: any) {
    return this.get(UrlService.getDatasetGroup(), params);
  }

  async updateDataset(dsID: string | undefined, values: any) {
    return this.post(UrlService.updateDataset(dsID), values);
  }

  async exportDataset(dsID: string | undefined) {
    return this.post(UrlService.exportDataset(dsID), {});
  }

  async fetchProjectDatasets(id: string | undefined) {
    return this.get(UrlService.fetchProjectDatasets(id), {});
  }

  async getProjectDatasetPositions(projID: string | undefined) {
    return this.get(UrlService.getProjectDatasetPositions(projID), {});
  }

  async setDatasetStatus(dsID: string | undefined, newStatus: string) {
    return this.post(UrlService.setDatasetStatus(dsID), { status: newStatus });
  }

  async getDatasetGraph(dsID: string | undefined) {
    return this.get(UrlService.getDatasetGraph(dsID), {});
  }

  async uploadReport(projID: string | undefined, values: any) {
    return this.post(UrlService.uploadReport(projID), values, { "Content-Type": "multipart/form-data" });
  }

  async uploadProjectDoc(projID: string | undefined, values: any) {
    return this.post(UrlService.uploadProjectDoc(projID), values, { "Content-Type": "multipart/form-data" });
  }

  async excelParse(values: any) {
    return this.post(UrlService.excelParse(), values, {'Content-Type': 'multipart/form-data'});
  }

  async importPositions(projID: string | undefined, values: any) {
    return this.post(UrlService.importPositions(projID), values);
  }

  async getProjectDocs(projID: string | undefined) {
    return this.get(UrlService.getProjectDocs(projID), {});
  }

  async getNewsFeed(values: any) {
    return this.get(UrlService.getNewsFeed(), values);
  }

  async getSingleNews(newsID: string | undefined) {
    return this.get(UrlService.getSingleNews(newsID), {});
  }

  async getDashboardSettings() {
    return this.get(UrlService.getDashboardSettings(), {});
  }

  async saveDashboardSettings(values: any) {
    return this.post(UrlService.saveDashboardSettings(), values);
  }

  async updateDatasetData(dsID: string, values: any) {
    return this.post(UrlService.updateDatasetData(dsID), values);
  }

  async bulkDeleteDatasetData(dsID: string, values: any) {
    return this.post(UrlService.bulkDeleteDatasetData(dsID), values);
  }

  async getReports(count: number) {
    return this.get(UrlService.getReports(), { count: count });
  }

  async getLicenses() {
    return this.get(UrlService.getLicenses(), {});
  }

  async getLicenseTypes() {
    return this.get(UrlService.getLicenseTypes(), {});
  }

  async setLicenseStatus(licenseID: string | undefined, newStatus: string) {
    return this.post(UrlService.setLicenseStatus(licenseID), { status: newStatus });
  }

  async addLicense(values: any) {
    const formData = new FormData();

    for (var key in values) {
      formData.append(key, values[key]);
    }

    return this.post(UrlService.addLicense(), values, { "Content-Type": "multipart/form-data" });
  }

  async addLicenseType(values: any) {
    return this.post(UrlService.addLicenseType(), values);
  }

  async updateLicense(licenseID: string | undefined, values: any) {
    return this.post(UrlService.updateLicense(licenseID), values, { "Content-Type": "multipart/form-data" });
  }

  async deleteLicense(licenseID: string | undefined) {
    return await this.delete(UrlService.deleteLicense(licenseID), {});
  }

  async getRentals() {
    return this.get(UrlService.getRentals(), {});
  }

  async storeAddRental(values: any) {
    return this.post(UrlService.storeAddRental(), values);
  }

  async deleteRental(rentID: string | undefined) {
    return await this.delete(UrlService.deleteRental(rentID), {});
  }

  async getMachineBookings(machineID: number | undefined, values: any) {
    return await this.get(UrlService.getMachineBookings(machineID), values);
  }

  async setRentalStatus(rentID: number | undefined, newStatus: number, checklists: any) {
    return await this.post(UrlService.setRentalStatus(rentID), { status: newStatus, checklists });
  }

  async getRentalStatusLog(rentID: number | undefined, params: any) {
    return await this.post(UrlService.getRentalStatusLog(rentID), params);
  }

  async getRentalPrices() {
    return this.get(UrlService.getRentalPrices(), {});
  }

  async retrieveAvailableProjects(params: any) {
    return await this.post(UrlService.retrieveAvailableProjects(), params);
  }

  async getMachineIssues() {
    return this.get(UrlService.getMachineIssues(), {});
  }

  async storeMachineIssue(values: any) {
    return this.post(UrlService.storeMachineIssue(), values, {'Content-Type': 'multipart/form-data'});
  }

  async updateMachineIssue(id: number, values: any) {
    return this.post(UrlService.updateMachineIssue(id), values, {'Content-Type': 'multipart/form-data'});
  }

  async destroyMachineIssue(id: number) {
    return this.delete(UrlService.destroyMachineIssue(id), {});
  }

  async storeMachineIssueComment(values: any) {
    return this.post(UrlService.storeMachineIssueComment(), values, {'Content-Type': 'multipart/form-data'});
  }

  async exportPositions(id: any, data: any) {
    return await fetch(UrlService.exportPositions(id), {
      method: 'POST',
      body: JSON.stringify({data}),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('_kcauthtoken')}`
      }
    });
  }

  async bookMachine(machine_id: number, values: any) {
    return this.post(UrlService.bookMachine(machine_id), values);
  }
}

export default new ApiService();