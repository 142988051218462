import { styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    borderColor: 'primary.light !important',
    overflowX: 'scroll',
    color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,1)',
    '& .MuiDataGrid-columnsContainer': {
        backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
    },
    '& .MuiDataGrid-columnHeader': {
        borderTopLeftRadius: 0,
    },
    '& .MuiDataGrid-iconSeparator': {
        display: 'none',
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        borderBottom: `1px solid ${ theme.palette.mode === 'light' ? '#f0f0f0' : '#505050' }`,
    },
    '& .MuiDataGrid-row:hover': {
        backgroundColor: "#1d2146",
    },
    '& .MuiDataGrid-cell': {
        color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.4)' : 'rgba(255,255,255,0.65)',
    },
    '& .MuiDataGrid-cell:hover': {
        color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.4)' : 'rgba(255,255,255,1) !important',
    },
    '& .MuiPaginationItem-root': {
        borderRadius: 0,
    },
    '& .MuiDataGrid-filterForm': {
        color: "#ff0000",
        display: "none",
        backgroundColor: "#ff0",
    },
    '& .data-row-inactive': {
        backgroundColor: 'rgba(53, 54, 50, 0.3)',
        
        '&:hover': {
          backgroundColor: 'rgba(53, 54, 50, 0.4)',
        },
    },
    '& .data-row-inactive .MuiDataGrid-cell': {
        color: 'rgba(203, 204, 210, 0.4)',
      }
}));