import React from "react";
import { Snackbar, Alert, SlideProps, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
  }

function AlertSnackbar(props: any) {
  const { open, handleClose, message, type} = props;
  const [state, setState] = React.useState<{
    open: boolean;
    Transition: React.ComponentType<
      TransitionProps & {
        children: React.ReactElement<any, any>;      }
    >;
  }>({
    open: false,
    Transition: Slide,
  });

  return (
    <div>
      {" "}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} TransitionComponent={state.Transition} key={state.Transition.name} anchorOrigin={{ vertical: 'bottom', horizontal: 'right',}}>
        <Alert onClose={handleClose} severity={type || "success"}>
         {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AlertSnackbar;