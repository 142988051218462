const styleModal = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        md: 800,
        sm: 460,
        xs: 320
    },
    bgcolor: 'background.paper',
    boxShadow: "0px 0px 2px #6a7199",
    borderRadius: '10px',
    p: 4,
    overflowY: "auto",
    scroll: "paper.scroll",
    maxHeight: '90%',
    scrollbarColor: '#333751 #0b0f26',
    scrollbarWidth: 'thin',
};
export default styleModal;

export const styleModalLarge = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        md: 1080,
        sm: 460,
        xs: 320
    },
    bgcolor: 'background.paper',
    boxShadow: "0px 0px 2px #6a7199",
    borderRadius: '10px',
    overflowY: "auto",
    scroll: "paper.scroll",
    maxHeight: '90%',
    scrollbarColor: '#333751 #0b0f26',
    scrollbarWidth: 'thin',
};

export const styleModalSmall = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        md: 640,
        sm: 460,
        xs: 320
    },
    bgcolor: 'background.paper',
    boxShadow: "0px 0px 2px #6a7199",
    borderRadius: '10px',
    p: 4,
    overflowY: "auto",
    scroll: "paper.scroll",
    maxHeight: '90%',
    scrollbarColor: '#333751 #0b0f26',
    scrollbarWidth: 'thin',
};

export const styleModalXS = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        md: 440,
        sm: 320,
        xs: 280
    },
    bgcolor: 'background.paper',
    boxShadow: "0px 0px 2px #6a7199",
    borderRadius: '10px',
    px: 4,
    py: 3,
    overflowY: "auto",
    scroll: "paper.scroll",
    maxHeight: '90%',
    scrollbarColor: '#333751 #0b0f26',
    scrollbarWidth: 'thin',
};

export const styleModalImage = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: {
        md: '90vh',
        sm: 460,
        xs: 320
    },
    boxShadow: "0px 0px 2px #6a7199",
    borderRadius: '10px',
    scrollbarColor: '#333751 #0b0f26',
    scrollbarWidth: 'thin',
};

export const styleModalChart = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: {
        md: '90vh',
        sm: 460,
        xs: 320
    },
    boxShadow: "0px 0px 2px #6a7199",
    borderRadius: '10px',
    scrollbarColor: '#333751 #0b0f26',
    scrollbarWidth: 'thin',
};