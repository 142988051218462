import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useUserAuth } from "../contexts/UserContext";

export const RequireAuth = ({ children }: { children: any }) => {
  var userAuth = useUserAuth();
  
  //check if the user is already logged in on first load
  /*if (storedToken) {
    const uDetails = JSON.parse(localStorage.getItem('_kcuserdetails') as string);
    userAuth.setLogin({
      token: storedToken,
      userDetails: uDetails
    });

    userAuth = useUserAuth();
  }*/

  if (!userAuth.authToken) {
    return <Navigate to="/" />
  }

  return children;

}

export default RequireAuth;