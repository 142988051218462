import { useState, createContext, useContext, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import ApiService from '../services/ApiService';
import CookieService from '../services/CookieService';

type UserDetails = { id: number, name: string, role: string, permissions: any[], company_name: string, organization: any };

type UserContext = {
  authToken?: string;
  userDetails:  UserDetails | null;
  setLogin: (user: any) => void;
  setLogout: () => void;
};

export const UserContext = createContext<UserContext>({} as UserContext);

export const UserProvider = ({ children }: { children: any }) => {
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState("");
  const [userDetails, setUserDetails] = useState<UserDetails | null>({id: 0, name: '', role: '', permissions: [], company_name: '', organization: {} });
  const locationDetails = useLocation();

  const verifyAuthToken = async () => {
    const response = await ApiService.verifyAuthToken();

    if (response.status == 'success') {
      setUserDetails(response.userDetails)
    } else {
      //logout and redirect to login
      if(locationDetails.pathname != '/reset_password' && locationDetails.pathname != '/en' && locationDetails.pathname != '/de') {
        navigate('/', {replace: true});
      }
    }
  }

  const logoutFromServer = () => {
    ApiService.logout();//continue without waiting
  }

  useEffect(() => {
    (async () => {
      const excludePaths = ['login', 'forgot_password', 'reset_password', 'en', 'de', 'store'];
      const pathname = window.location.pathname;
      const pathMatched = excludePaths.filter(item => pathname.includes(item));
      
      //varify if user is loggedin
      if(pathMatched.length == 0) {
        verifyAuthToken();
      }
    })();
  }, []);

  const setLogin = (data: any) => {
    setAuthToken(data.token);

    if (data.userDetails != undefined) {
      var uDetail = data.userDetails

      setUserDetails(uDetail);
    }
  };

  const setLogout = () => {
    logoutFromServer();
    setAuthToken("");
    setUserDetails(null);
    localStorage.clear();
    CookieService.remove("axw3sdvgdshelpaccess", {path: "/", domain: '.krinnerconnect.com'});
    CookieService.remove("_kcaccesstoken", {path: "/"});
    navigate('/');
  }

  const storedToken = localStorage.getItem('_kcauthtoken');

  if (authToken == "" && storedToken) {
    //const uDetails = JSON.parse(localStorage.getItem('_kcuserdetails') as string);
    setLogin({
      token: storedToken,
      //userDetails: uDetails
    });
  }

  return (
    <UserContext.Provider
      value={{ authToken, userDetails, setLogin, setLogout }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserAuth = () => {
  return useContext(UserContext);
}

export const hasPermission = (strPerm: string) => {
  const userAuth = useContext(UserContext);
  return userAuth.userDetails!.permissions.indexOf(strPerm) >= 0;
}