import { useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, capitalize, Divider, Hidden, lighten, List, ListItem, ListItemText, Popover, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { ModeEditOutlineTwoTone, SettingsTwoTone } from '@mui/icons-material';
import { useUserAuth } from '../../../../contexts/UserContext';
import { t } from 'i18next';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const RoleLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        font-size: 10px;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
const userAuth = useUserAuth();
const navigate = useNavigate();

  const user = {
    name: 'Manny Rumbos',
    avatar: '/static/images/avatars/1.jpg',
    jobtitle: 'Project Manager'
  };

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = () => {
    //logout from server
    //console.log(userAuth.userDetails);

    //logout from client
    userAuth.setLogout();
    navigate('/');
  }

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" src={user.avatar} />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{userAuth.userDetails && userAuth.userDetails.name} {userAuth.userDetails && <small><i>({userAuth.userDetails.company_name})</i></small>}</UserBoxLabel>
            <RoleLabel>{userAuth.userDetails && capitalize(userAuth.userDetails.role)}</RoleLabel>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" src={user.avatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{userAuth.userDetails && userAuth.userDetails.name}</UserBoxLabel>
            <RoleLabel>{userAuth.userDetails && capitalize(userAuth.userDetails.role)}</RoleLabel>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem
            button
            to="/admin/profile"
            component={NavLink}
          >
            <ModeEditOutlineTwoTone fontSize="small" />
            <ListItemText primary={t('profile')} />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            {t('logout')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
