import { useRoutes } from 'react-router-dom';
import router from './router';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';
import { UserProvider } from './contexts/UserContext';
import { useLoadScript } from '@react-google-maps/api';

function App() {
  const content = useRoutes(router);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDDSDD5fxfLg5ukUokydYnk4fdNHIxPIXk",
    libraries: ["places"],
  });

  return (
    <UserProvider>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          {content}
        </LocalizationProvider>
      </ThemeProvider>
    </UserProvider>
  );
}
export default App;
