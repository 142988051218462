import i18n, { init } from 'i18next';
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
//var enL = import('./en/translation.json');
import enL from "./en/translation.json";
import deL from "./de/translation.json";

const resources = {
  en: {
    translation : enL
  },
  de : {
    translation : deL
  }
};

const currentLang = localStorage.getItem('lng') || "en";

const initSettings = {
  resources : resources,
  lng : currentLang,
  debug: true,
  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  }
};

console.log(currentLang);

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(initSettings);

  export default i18n