import { t } from 'i18next'
import React from 'react'

export default function getStatusName(params:any) {
  switch(Math.abs(params.value)) {
    case -1: return t('deleted');
    case 0: return t('inactive');
    case 1: return t('active');
  }
}
