import { Suspense, lazy, Children } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import BaseLayout from './layouts/BaseLayout';
import SidebarLayout from './layouts/SidebarLayout';
import SuspenseLoader from './components/SuspenseLoader';
import RequireAuth from './components/RequireAuth';
import Organizations from './content/admin/organizations';

const Loader = (Component: any) => (props: JSX.IntrinsicAttributes) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Login = Loader(lazy(() => import('./content/login')));
const RequestPassword = Loader(lazy(() => import('./content/login/RequestPassword')));
const ResetPassword = Loader(lazy(() => import('./content/login/ResetPassword')));
const Welcome = Loader(lazy(() => import('./content/login/Welcome')));
const LandingPage = Loader(lazy(() => import('./content/pages/LandingPage')));

// Status
const Status404 = Loader(
  lazy(() => import('./content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('./content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('./content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('./content/pages/Status/Maintenance'))
);

//admin access
const AdminDashboard = Loader(
  lazy(() => import('./content/admin/dashboard'))
);

const AdminMachines = Loader(
  lazy(() => import('./content/admin/machines'))
);

const AdminMachineIssues = Loader(
    lazy(() => import('./content/admin/machine-issues'))
);

const AdminScrews = Loader(
  lazy(() => import('./content/admin/screws'))
)

const AdminSensors = Loader(
  lazy(() => import('./content/admin/sensors'))
)

const AdminCarriers = Loader(
  lazy(() => import('./content/admin/carriers'))
)

const AdminUsers = Loader(
  lazy(() => import('./content/admin/users'))
)

const AdminOrganizations = Loader(
  lazy(() => import('./content/admin/organizations'))
)

const AdminInstallers = Loader(
  lazy(() => import('./content/admin/installers'))
)

const AdminRoles = Loader(
  lazy(() => import('./content/admin/roles'))
)

const AdminProfile = Loader(
  lazy(() => import('./content/admin/profile'))
)

const AdminSettings = Loader(
  lazy(() => import('./content/admin/settings'))
)

const TorqueHeads = Loader(
  lazy(() => import('./content/admin/torquehead'))
)

const AdminRecordingDevices = Loader(
  lazy(() => import('./content/admin/recordingDevice'))
)

const Firmwares = Loader(
  lazy(() => import('./content/admin/firmware'))
)

const AdminProjects = Loader(
  lazy(() => import('./content/admin/projects'))
)

const AdminProject = Loader(
    lazy(() => import('./content/admin/projects/project'))
)

const AdminDatasets = Loader(
  lazy(() => import('./content/admin/datasets'))
)

const Licenses = Loader(
  lazy(() => import('./content/admin/licenses'))
)

const Rentals = Loader(
  lazy(() => import('./content/admin/rentals'))
)

//for testing components and functionalities
const TestPage = Loader(
  lazy(() => import('./content/test'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Login />
      },
      {
        path: 'login',
        element: <Navigate to="/" replace />
      },
      {
        path: 'forgot_password',
        element: <RequestPassword />
      },
      {
        path: 'reset_password',
        element: <ResetPassword />
      },
      {
        path: 'welcome',
        element: <Welcome />
      },
      {
        path: 'en',
        element: <LandingPage />
      },
      {
        path: 'de',
        element: <LandingPage />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'admin',
    element: <RequireAuth><SidebarLayout /></RequireAuth>,
    children: [
      {
        path: 'dashboard',
        element: <AdminDashboard />
      },
      {
        path: 'projects',
        element: <AdminProjects />
      },
      {
        path: 'projects/:id',
        element: <AdminProject />
      },
      {
        path: 'machines',
        element: <AdminMachines />
      },
      {
        path: 'bookable-machines',
        element: <AdminMachines />
      },
      {
        path: 'book-machine',
        element: <AdminMachines />
      },
      {
        path: 'machine-issues',
        element: <AdminMachineIssues />
      },
      {
        path: 'screws',
        element: <AdminScrews />
      },
      {
        path: 'sensors',
        element: <AdminSensors />
      },
      {
        path: 'carriers',
        element: <AdminCarriers />
      },
      {
        path: 'recording_devices',
        element: <AdminRecordingDevices />
      },
      {
        path: 'firmwares',
        element: <Firmwares />
      },
      {
        path: 'users',
        element: <AdminUsers />
      },
      {
        path: 'organizations',
        element: <Organizations />
      },
      {
        path: 'installers',
        element: <AdminInstallers />
      },
      {
        path: 'profile',
        element: <AdminProfile />
      },
      {
        path: 'settings',
        element: <AdminSettings />
      },
      {
        path: 'roles',
        element: <AdminRoles />
      },
      {
        path: 'torqueHeads',
        element: <TorqueHeads />
      },
      {
        path: 'datasets',
        element: <AdminDatasets />
      },
      {
        path: 'licenses',
        element: <Licenses />
      },
      {
        path: 'rentals',
        element: <Rentals />
      }
    ]
  },
  {
    path: 'test',
    element: <BaseLayout />,
    children: [
      {
        path: 'testform',
        element: <TestPage />
      }
    ]
  }
];

export default routes;
