import { Helmet } from 'react-helmet-async';
import { Container, Grid, Typography, Card, Box, SxProps, Theme, Modal, Button, Tooltip, IconButton, LinearProgress } from '@mui/material';
import Footer from '../../../components/Footer';
import { GridColDef, GridRowsProp, GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { HTMLAttributes, RefAttributes, useEffect } from 'react';
import React from 'react';
import { StyledDataGrid } from '../../../components/Table/TableStyleGrid';
import { Add, CheckCircleOutline, Close, DeleteForeverOutlined, DeleteOutline, EditOutlined, NotInterestedOutlined, PersonRounded } from '@mui/icons-material';
import OrgForm from './OrgForm';
import { styleModalSmall } from '../../../components/Modal/ModalStyle';
import ApiService from '../../../services/ApiService';
import getStatusName from '../../../components/StatusName';
import ConfirmDialog from '../../../components/Alerts/ConfirmDialog';
import AlertSnackbar from '../../../components/Alerts/AlertSnackbar';
import { hasPermission } from '../../../contexts/UserContext';
import UserLists from './UserLists';
import { showWithToolTip } from '../../../components/Table/CellToolTip';

function Organizations() {
  const { t } = useTranslation();

  const [selectedOrgID, setSelectedOrgID] = React.useState(0);
  const [selectedOrgDetails, setSelectedOrgDetails] = React.useState([]);
  const [tableLoading, setTableLoading] = React.useState(false);
  const [snackBarAlert, setSnackBarAlert] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [confirmFor, setConfirmFor] = React.useState("");
  const [confirmTitle, setConfirmTitle] = React.useState("");
  const [confirmContent, setConfirmContent] = React.useState("");
  const [stateOpen, setOpenModal] = React.useState(false);
  const [stateOpenUsersModal, setOpenUsersModal] = React.useState(false);
  const handleOpenModal = () => { setOpenModal(true); }
  const handleOpenUsersModal = () => { setOpenUsersModal(true); }
  const handleCloseModal = () => { setOpenModal(false); setOpenUsersModal(false); }

  const initialRows: GridRowsProp = [];
  const [rows, setRows] = React.useState(initialRows);

  const columns: GridColDef[] = [
    { field: 'company_name', headerName: t('company_name'), flex: 1, renderCell: showWithToolTip },
    {
      field: 'owners', headerName: t('account_owners'), flex: 2, renderCell: showWithToolTip, valueGetter(params) {
        if (params.row.owners.length) {
          const ownerNames = params.row.owners.map((item: any) => item.name);
          return ownerNames.join(', ');
        } else {
          return 'N/A';
        }
      }
    },
    {
      field: 'is_store', headerName: t('has_store'), flex: 1, valueGetter(params) {
        if (parseInt(params.value) == 1) {
          return t('yes').toUpperCase();
        } else {
          return t('no').toUpperCase();
        }
      }
    },
    { field: 'status', headerName: 'Status', flex: 1, valueGetter: getStatusName },
    {
      field: 'action', headerName: t('action'), flex: 1, sortable: false,
      renderCell: (params) => {
        return <>
          <Tooltip title={t('users')}><IconButton color="primary" aria-label="Users" component="label" onClick={() => { setSelectedOrgID(params.row.id); setSelectedOrgDetails(params.row); handleOpenUsersModal(); }}>
            <PersonRounded />
          </IconButton></Tooltip>
          {hasPermission('organizations.edit') && <Tooltip title={t('edit')}><IconButton color="primary" aria-label="Edit" component="label" onClick={() => { setSelectedOrgID(params.row.id); setSelectedOrgDetails(params.row); handleOpenModal(); }}>
            <EditOutlined />
          </IconButton></Tooltip>}
          {
            hasPermission('organizations.edit') && (params.row.status == 1 ? <Tooltip title={t('deactivate')}><IconButton color="primary" aria-label="Deactivate" component="label" onClick={() => { setSelectedOrgID(params.id as number); setConfirmFor('deactivate'); setConfirmTitle(t('deactivate_org') + '?'); setConfirmContent(t('deactivate_org_confirm')); setConfirmOpen(true); }}>
              <NotInterestedOutlined color='secondary' />
            </IconButton></Tooltip> :
              <Tooltip title={t('activate')}><IconButton color="primary" aria-label="Activate" component="label" onClick={() => { setSelectedOrgID(params.id as number); setConfirmFor('activate'); setConfirmTitle(t('activate_org') + '?'); setConfirmContent(t('activate_org_confirm')); setConfirmOpen(true); }}>
                <CheckCircleOutline />
              </IconButton></Tooltip>)
          }
          {
            hasPermission('organizations.delete') && (params.row.users_count == 0 ? <Tooltip title={t('delete')}><IconButton color="primary" aria-label="Delete" component="label" onClick={() => { setSelectedOrgID(params.row.id); setConfirmFor('delete'); setConfirmTitle(t('delete_org') + '?'); setConfirmContent(t('delete_confirm')); setConfirmOpen(true); }}>
              <DeleteOutline />
            </IconButton></Tooltip> :
              <Tooltip title={t('org_linked')}><IconButton disableRipple ><DeleteForeverOutlined color='action' sx={{ opacity: 0.5 }} /></IconButton></Tooltip>)
          }
        </>
      }
    },
  ];

  function CustomToolbar(props: JSX.IntrinsicAttributes & HTMLAttributes<HTMLDivElement> & { sx?: SxProps<Theme> | undefined; } & RefAttributes<HTMLDivElement>) {
    return (
      <GridToolbarContainer {...props}>
        <GridToolbarFilterButton nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
        <GridToolbarExport />
        {hasPermission('organizations.add') && <Button size='small' onClick={() => { setSelectedOrgID(0); setSelectedOrgDetails([]); handleOpenModal(); }}><Add />{t('create_org')}</Button>}
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    (async () => {
      getOrgs();
    })();
  }, []);

  const getOrgs = async () => {
    setTableLoading(true);
    const response = await ApiService.getOrgs();

    setTableLoading(false);
    if (response.status == 'success') {
      setRows(response.data);
    } else {

    }
  }

  //actions
  const delateOrg = async (id: any) => {
    const response = await ApiService.deleteOrg(id);

    if (response && response.status == 'success') {
      setSnackBarMessage(t('success.selected_record_deleted'))
      setSnackBarAlert(true);

      getOrgs();
    } else {
      if (response.error) {

      }
    }
  }

  const setOrgStatus = async (id: any, status: string) => {
    const response = await ApiService.setOrgStatus(id, status);

    if (response && response.status == 'success') {
      if (confirmFor == 'activate') {
        setSnackBarMessage(t('success.selected_record_activated'));
      } else {
        setSnackBarMessage(t('success.selected_record_deactivated'));
      }

      setSnackBarAlert(true);

      getOrgs();
    } else {
      if (response.error) {

      }
    }
  }

  const handleOnConfirm = () => {
    if (confirmFor) {
      switch (confirmFor) {
        case 'delete': delateOrg(selectedOrgID);
          break;
        case 'activate': setOrgStatus(selectedOrgID, 'active');
          break;
        case 'deactivate': setOrgStatus(selectedOrgID, 'inactive');
          break;
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>{t('organizations')}</title>
      </Helmet>
      <Container maxWidth="xl">
        <Card sx={{ overflow: 'visible', mt: 3 }}>
          <Box display="flex" alignItems="center" sx={{ pl: 3, pt: 3, pb: 0 }}>
            <Box>
              <Typography variant="h4" noWrap mb={1}>
                {t('organizations')}
              </Typography>
            </Box>
          </Box>
          <StyledDataGrid
            sx={{
              borderColor: 'primary.light',
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.light',
              },
              '& .MuiDataGrid-table': {
                border: 'none'
              },
              '& .MuiDataGrid-filterForm': {
                color: "#ff0000",
                display: "none",
              }
            }}
            rows={rows}
            columns={columns}
            autoHeight={true}
            loading={tableLoading}
            localeText={{
              toolbarFilters: "Filters",
              toolbarExport: "Export"
            }}
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar,
              LoadingOverlay: LinearProgress,
            }}
          ></StyledDataGrid>
        </Card>
      </Container>
      <Modal
        open={stateOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalSmall}>
          <OrgForm orgID={selectedOrgID} orgData={selectedOrgDetails} closeModal={handleCloseModal} getOrgs={getOrgs}></OrgForm>
        </Box>
      </Modal>
      <Modal
        open={stateOpenUsersModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalSmall} style={{ padding: 0 }}>
          {stateOpenUsersModal ? (
            <IconButton
              aria-label="close"
              onClick={() => { handleCloseModal(); }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          ) : null}
          <UserLists orgID={selectedOrgID} orgData={selectedOrgDetails} closeModal={handleCloseModal}></UserLists>
        </Box>
      </Modal>
      <Footer />
      <ConfirmDialog
        title={confirmTitle}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleOnConfirm}
      >
        {confirmContent}
      </ConfirmDialog>
      <AlertSnackbar open={snackBarAlert} handleClose={() => setSnackBarAlert(false)} message={snackBarMessage} />
    </>
  );
}

export default Organizations;
