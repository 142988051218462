import * as Yup from "yup";
import Uploader from "./uploader";
import {Form, Formik} from "formik";
import {Close} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import React, {useState, useEffect} from "react";
import ApiService from "../../../../../services/ApiService";
import styleModal from "../../../../../components/Modal/ModalStyle";
import RegularLabel from "../../../../../components/Forms/RegularLabel";
import AlertSnackbar from "../../../../../components/Alerts/AlertSnackbar";
import RegularDropdown from "../../../../../components/Forms/RegularDropdown";
import {
    Box,
    Grid,
    Modal,
    Button,
    Portal,
    Divider,
    MenuItem,
    IconButton,
    FormControl,
    FormHelperText
} from "@mui/material";

const Index = () => {
    const {t} = useTranslation();

    const [popup, setPopup] = useState(false);
    const [machines, setMachines] = useState([]);
    const [snackBarAlert, setSnackBarAlert] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState(false);

    const [values, setValues] = useState({
        files: [],
        status: '',
        machine_id: ''
    });

    const _validationSchema = Yup.object().shape({
        files: Yup.array().min(1, t('errors.required')),
        status: Yup.string().required(t('errors.required')),
        machine_id: Yup.string().required(t('errors.required'))
    });

    useEffect(() => {
        if (popup) {
            fetchMachines().then(machines => setMachines(machines));
        }
    }, [popup]);

    const fetchMachines = async () => {
        const response = await ApiService.getMachines();

        if (response.status === 'success') {
            return response.data;
        }

        return [];
    };

    const submit = async (values: any, form: any) => {
        const formData = new FormData();

        for (const key in values) {
            if (key === 'files') {
                for (const index in values[key]) {
                    formData.append(`files[${index}][file]`, values[key][index].file);
                    formData.append(`files[${index}][message]`, values[key][index].message);
                }
            } else {
                formData.append(key, values[key]);
            }
        }

        form.setSubmitting(true);

        const response = await ApiService.storeMachineIssue(formData);

        form.setSubmitting(false);

        if (response.success) {
            form.resetForm();

            setValues({
                files: [],
                status: '',
                machine_id: ''
            });

            window.dispatchEvent(new CustomEvent('report-issue', {
                detail: {record: response.record}
            }));

            setPopup(false);
            setSnackBarAlert(true);
            setSnackBarMessage(response.message);

            return true;
        }

        for (const field in response.errors) {
            if (field === 'files') {
                form.setFieldError(field, response.errors[field][0]);
            }
            if (Object.keys(values).includes(field)) {
                form.setFieldError(field, response.errors[field][0]);
            }
        }

        let files = [...values.files];

        for (const field of Object.keys(response.errors).filter((field: string) => field.includes('files.'))) {
            let data = field.split('.');

            files = files.map((item: any, index: number) => {
                if (index === +data[1]) {
                    return {
                        ...item, errors: {
                            ...item.errors,
                            [data[2]]: response.errors[field][0].replace(field, data[2])
                        }
                    };
                }
                return item;
            });
        }

        form.setFieldValue('files', files, false);

        return true;
    };

    return <>
        <Button
            sx={{mr: 2}}
            size="small"
            type="button"
            variant="outlined"
            onClick={() => setPopup(true)}
        >{t('report_issue')}</Button>
        <Modal open={popup} onClose={() => setPopup(false)}>
            <Box sx={{...styleModal, pt: 6}}>
                <IconButton
                    onClick={() => setPopup(false)}
                    sx={{top: 8, right: 8, position: 'absolute', color: theme => theme.palette.grey[500]}}
                ><Close/></IconButton>
                <Formik
                    onSubmit={submit}
                    initialValues={values}
                    enableReinitialize={true}
                    validationSchema={_validationSchema}
                >
                    {parameters => {
                        const {
                            errors,
                            values,
                            touched,
                            handleBlur,
                            isSubmitting,
                            handleChange,
                            handleSubmit,
                            setFieldValue
                        } = parameters;

                        const statuses = ['unknown', 'repair_works', 'spare_parts_changes', 'defects'];

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Grid container spacing={2} sx={{mt: 2}}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth variant="standard">
                                            <Uploader
                                                files={values.files}
                                                update={setFieldValue}
                                            />
                                            {errors.files && touched.files && (
                                                <FormHelperText error>{errors.files}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth variant="standard">
                                            <RegularLabel shrink htmlFor="status">
                                                {t('status')}*
                                            </RegularLabel>
                                            <RegularDropdown
                                                fullWidth
                                                id="status"
                                                name="status"
                                                disableUnderline
                                                onBlur={handleBlur}
                                                value={values.status}
                                                onChange={handleChange}
                                                error={!!(errors.status && touched.status)}
                                            >
                                                {statuses.map((status) => (
                                                    <MenuItem key={status} value={status}>
                                                        {t(`issue_statuses.${status}`)}
                                                    </MenuItem>
                                                ))}
                                            </RegularDropdown>
                                            {errors.status && touched.status && (
                                                <FormHelperText error>{errors.status}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth variant="standard">
                                            <RegularLabel shrink htmlFor="machine_id">
                                                {t('machine')}*
                                            </RegularLabel>
                                            <RegularDropdown
                                                fullWidth
                                                id="machine_id"
                                                name="machine_id"
                                                disableUnderline
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.machine_id}
                                                error={!!(errors.machine_id && touched.machine_id)}
                                            >
                                                {machines.map((machine: any) => (
                                                    <MenuItem key={machine.id} value={machine.id}>
                                                        {machine.name}
                                                    </MenuItem>
                                                ))}
                                            </RegularDropdown>
                                            {errors.machine_id && touched.machine_id && (
                                                <FormHelperText error>{errors.machine_id}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Divider light sx={{my: 3}}/>
                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                    <Button
                                        size="large"
                                        type="button"
                                        variant="outlined"
                                        onClick={() => setPopup(false)}
                                    >{t('close')}</Button>
                                    <Box sx={{flex: '1 1 auto'}}/>
                                    <Button
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        disabled={isSubmitting}
                                    >{t('save')}</Button>
                                </Box>
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </Modal>
        <Portal>
            <AlertSnackbar
                open={snackBarAlert}
                message={snackBarMessage}
                handleClose={() => setSnackBarAlert(false)}
            />
        </Portal>
    </>;
};

export default Index;