import * as Yup from "yup";
import {Formik} from "formik";
import {Box} from "@mui/system";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ApiService from "../../../services/ApiService";
import RegularLabel from "../../../components/Forms/RegularLabel";
import RegularInput from "../../../components/Forms/RegularInput";
import RegularDropdown from "../../../components/Forms/RegularDropdown";
import {
    Grid,
    Button,
    Divider,
    MenuItem,
    Typography,
    FormControl,
    ListItemText,
    FormHelperText
} from "@mui/material";

const UserForm = (props: any) => {
    const {t} = useTranslation();

    const {close, getUsers} = props;

    const [roles, setRoles] = useState<any[]>([]);

    const initialValues = {
        email: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        mobile_number: '',
        fax: '',
        org_id: '0',
        role_id: '2'
    };

    const _validationSchema = Yup.object({
        email: Yup.string().required(t('errors.required')).email(t('errors.valid_email')),
        first_name: Yup.string().required(t('errors.required')),
        last_name: Yup.string().required(t('errors.required')),
        org_id: Yup.number().required(t('errors.required')),
        role_id: Yup.string().required(t('errors.required'))
    });

    useEffect(() => {
        (async () => {
            await ApiService.getRoles().then(({status, data}) => {
                if (status === 'success') {
                    setRoles(data.filter((role: any) => role.id === 2));
                }
            }).catch(() => {});
        })();
    }, []);

    const submit = async (values: any, form: any) => {
        form.setSubmitting(true);

        await ApiService.addUser({...values, from_org: true}).then(async response => {
            if (response.status === 'success') {
                await getUsers();

                close();
            } else {
                if (response.error) {
                    Object.keys(response.error).forEach(function (key: string) {
                        form.setFieldError(key, t('errors.' + response.error[key][0]));
                    });
                }
            }
        }).finally(() => {
            form.setSubmitting(false);
        });
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12} mb={3}>
                    <Typography variant="h6" component="h2" align="center">
                        {t('add_user')}
                    </Typography>
                </Grid>
                <Formik
                    onSubmit={submit}
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={_validationSchema}
                >{
                    (properties) => {
                        const {
                            errors,
                            values,
                            touched,
                            handleBlur,
                            isSubmitting,
                            handleChange,
                            handleSubmit
                        } = properties;

                        return (
                            <form onSubmit={handleSubmit}>
                                <FormControl fullWidth>
                                    <RegularLabel shrink htmlFor="email" variant="standard">
                                        {t('email')}*
                                    </RegularLabel>
                                    <RegularInput
                                        fullWidth
                                        id="email"
                                        type="email"
                                        sx={{mb: 4}}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        onChange={handleChange}
                                        error={!!(errors.email && touched.email)}
                                    />
                                    {errors.email && touched.email && (
                                        <FormHelperText error>{errors.email}</FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl fullWidth variant="standard" sx={{mt: 2}}>
                                    <RegularLabel shrink htmlFor="first_name">
                                        {t('first_name')}*
                                    </RegularLabel>
                                    <RegularInput
                                        fullWidth
                                        type="text"
                                        id="first_name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.first_name}
                                        error={!!(errors.first_name && touched.first_name)}
                                    />
                                    {errors.first_name && touched.first_name && (
                                        <FormHelperText error>{errors.first_name}</FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl fullWidth variant="standard" sx={{mt: 2}}>
                                    <RegularLabel shrink htmlFor="last_name">
                                        {t('last_name')}*
                                    </RegularLabel>
                                    <RegularInput
                                        fullWidth
                                        type="text"
                                        id="last_name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.last_name}
                                        error={!!(errors.last_name && touched.last_name)}
                                    />
                                    {errors.last_name && touched.last_name && (
                                        <FormHelperText error>{errors.last_name}</FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl fullWidth variant="standard" sx={{mt: 2}}>
                                    <RegularLabel shrink htmlFor="phone_number">
                                        {t('phone_number')}
                                    </RegularLabel>
                                    <RegularInput
                                        fullWidth
                                        type="text"
                                        id="phone_number"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.phone_number}
                                        error={!!(errors.phone_number && touched.phone_number)}
                                    />
                                    {errors.phone_number && touched.phone_number && (
                                        <FormHelperText error>{errors.phone_number}</FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl fullWidth variant="standard" sx={{mt: 2}}>
                                    <RegularLabel shrink htmlFor="mobile_number">
                                        {t('mobile_number')}
                                    </RegularLabel>
                                    <RegularInput
                                        fullWidth
                                        type="text"
                                        id="mobile_number"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.mobile_number}
                                        error={!!(errors.mobile_number && touched.mobile_number)}
                                    />
                                    {errors.mobile_number && touched.mobile_number && (
                                        <FormHelperText error>{errors.mobile_number}</FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl fullWidth variant="standard" sx={{mt: 2}}>
                                    <RegularLabel shrink htmlFor="fax">
                                        {t('fax')}
                                    </RegularLabel>
                                    <RegularInput
                                        id="fax"
                                        fullWidth
                                        type="text"
                                        value={values.fax}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={!!(errors.fax && touched.fax)}
                                    />
                                    {errors.fax && touched.fax && (
                                        <FormHelperText error>{errors.fax}</FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl fullWidth variant="standard" sx={{mt: 2}}>
                                    <RegularLabel shrink htmlFor="role_id">{t('role')}*</RegularLabel>
                                    <RegularDropdown
                                        fullWidth
                                        id="role_id"
                                        name="role_id"
                                        disableUnderline
                                        onBlur={handleBlur}
                                        value={values.role_id}
                                        onChange={handleChange}
                                        error={!!(errors.role_id && touched.role_id)}
                                    >
                                        {roles.map(role => (
                                            <MenuItem key={`role-${role.id}`} value={role.id}>
                                                <ListItemText primary={role.name.toUpperCase()}/>
                                            </MenuItem>
                                        ))}
                                    </RegularDropdown>
                                    {errors.role_id && touched.role_id && (
                                        <FormHelperText error>{errors.role_id}</FormHelperText>
                                    )}
                                </FormControl>
                                <Divider light sx={{my: 3}}/>
                                <Box
                                    sx={{mt: 2}}
                                    alignSelf="center"
                                    alignItems="center"
                                    alignContent="center"
                                    justifyContent="center"
                                >
                                    <Button
                                        size="large"
                                        sx={{mr: 4}}
                                        type="submit"
                                        variant="contained"
                                        disabled={isSubmitting}
                                    >{t('save')}</Button>
                                    <Button
                                        size="large"
                                        type="button"
                                        onClick={close}
                                        variant="outlined"
                                    >{t('close')}</Button>
                                </Box>
                            </form>
                        );
                    }}
                </Formik>
            </Grid>
        </>
    );
};

export default UserForm;