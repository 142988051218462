import {useTranslation} from "react-i18next";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import {Close, CloudUpload} from "@mui/icons-material";
import React, {useRef, useState, useEffect} from "react";
import RegularTextArea from "../../../../../components/Forms/RegularTextArea";

const Uploader = (props: any) => {
    const {t} = useTranslation();

    const {files, update} = props;

    const [active, setActive] = useState(null);

    const inputContent = () => {
        return (
            <div key="input-content" className="clickable-area">
                <CloudUpload fontSize="large"/>
                <span>Click or drag to upload files</span>
            </div>
        );
    };

    const onChangeStatus = ({meta: {id}, file}: any, status: string) => {
        setActive(null);

        if (status === 'done') {
            update('files', [...files, {
                id,
                file,
                message: '',
                errors: {file: '', message: ''}
            }]);
        }

        if (status === 'removed') {
            update('files', files.filter((file: any) => file.id !== id));
        }
    };

    const PreviewComponent = (props: any) => {
        const {id, name, previewUrl} = props.meta;

        const {remove} = props.fileWithMeta;

        const record = files.find((file: any) => file.id === id);

        if (!record) {
            return <></>;
        }

        const ref = useRef<any>(null);

        useEffect(() => {
            const input = ref.current;

            if (input && id === active) {
                input.focus();

                input.selectionStart = input.selectionEnd = input.value.length;
            }
        }, [record.message]);

        const handleChangeMessage = (event: any) => {
            const message = event.target.value;

            update('files', files.map((file: any) => {
                if (file.id === id) {
                    return {...file, message, errors: {...record.errors, message: ''}};
                }
                return file;
            }));

            setActive(id);
        };

        return (
            <div className="preview-container">
                <div className="preview-content">
                    <div className="image-area">
                        <img src={previewUrl} alt={name} title={name}/>
                    </div>
                    <div className="input-area">
                        <RegularTextArea
                            ref={ref}
                            value={record.message}
                            onChange={handleChangeMessage}
                            onBlur={() => setActive(null)}
                            placeholder={`${t('message')} (${t('optional')})`}
                        />
                    </div>
                    <div className="action-area">
                        <Close onClick={remove}/>
                    </div>
                </div>
                {record.errors.file && (<div className="preview-error">
                    {record.errors.file}
                </div>)}
                {record.errors.message && (<div className="preview-error">
                    {record.errors.message}
                </div>)}
            </div>
        );
    };

    return (
        <div className="report-issue-uploader">
            <Dropzone
                accept="image/*"
                inputContent={inputContent}
                onChangeStatus={onChangeStatus}
                PreviewComponent={PreviewComponent}
            />
        </div>
    );
};

export default Uploader;